import * as React from 'react'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'
import { PageLayout } from '@components/page-layout'
import { ProductDetailsMainInformation } from '@modules/shop/product-details/product-details-main-information'
import { ProductDetailsDescription } from '@modules/shop/product-details/product-details-description'
import { ProductDetailsRelatedProducts } from '@modules/shop/product-details/product-details-related-products'
import { recommendedItems } from '@modules/home'
import { ProductDetailsOpinion } from '@modules/shop/product-details/product-details-opinion'
import { useSelector } from 'react-redux'
import { getProductDetails } from '@store/actions/products-actions'
import { productDetailsSelector } from '@store/slices/products-slice'
import { ProductDetailsOpinions } from '@modules/shop/product-details/product-details-opinions'
import { ContentLoader } from '@components/content-loader'
import { useAppDispatch } from '@store/hooks'
import { useApiRequest } from '@hooks/use-api-request'

type Params = 'id'

export const ProductDetailsView = (): JSX.Element => {
  const { id } = useParams<Params>()

  const productDetails = useSelector(productDetailsSelector)

  const dispatch = useAppDispatch()
  const { isLoading, action: fetch } = useApiRequest(async () => {
    if (id) {
      await dispatch(getProductDetails(parseInt(id, 10)))
    }
  })

  React.useEffect(() => {
    fetch()
  }, [id])

  const opinionsShortList = R.take(2, productDetails ? productDetails.opinions : [])

  return (
    <PageLayout showSidebar={false}>
      <ContentLoader isLoading={isLoading}>
        {productDetails && (
          <>
            <ProductDetailsMainInformation product={productDetails} />
            <hr className="text-muted my-5" />
            <div className="d-lg-flex d-block">
              <ProductDetailsDescription product={productDetails} />
              <div>
                {!!productDetails.opinions.length && (
                  <div>
                    <p className="text-success font-size-20 fw-semi-bold">Opinie</p>
                    <div>
                      {opinionsShortList.map(opinion => (
                        <ProductDetailsOpinion opinion={opinion} key={opinion.id} />
                      ))}
                    </div>
                    <p className="text-success ms-3 mb-5">Wszytskie opinie</p>
                  </div>
                )}
                <div className="bg-light-gray p-lg-5 p-4 border-small ">
                  UWAGA jesteśmy podmiotem profesjonalnym który został zarejestrowany w bazie PIORiN pod nr:
                  PL-24/66/7059 i posiadamy stosowne uprawnienia do wystawiania EU paszportów roślinnych i kontrolowania
                  jakości materiału roślinnego w związku z tym zgodnie z Rozporządzenie Wykonawcze Komisji (UE)
                  2019/2072 z dnia 28 listopada 2019 r. do każdego zamówionego słonecznika dołączony jest EU paszport
                  nasienny który umiejscowiony jest trwale na opakowaniu oraz na karcie charakterystyki wraz z kodami:
                  EAN, nasiona są spakowane hermetycznie do pojemników masowych 100 / 1000 g typu: Doypack z oknem
                  wizyjnym i struną, opakowanie jest trwale zamknięta oraz zaplombowane (typ plomby hologram). W razie
                  pytań prosimy o kontakt 602353022.
                </div>
              </div>
            </div>
            <hr className="text-muted my-5" />
            <ProductDetailsRelatedProducts products={recommendedItems.news} />
            <ProductDetailsOpinions productDetails={productDetails} />
          </>
        )}
      </ContentLoader>
    </PageLayout>
  )
}
