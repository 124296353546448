import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { useToggle } from '@hooks/use-toggle'

interface Props {
  isOpen: boolean
  handleClose: () => void
  message?: string
  title?: string
  buttonText?: string
}

export const SuccessModal: React.FC<Props> = ({ isOpen, handleClose, message, title, buttonText }) => {
  const [isShow, handleHide] = useToggle(isOpen)

  return (
    <Modal size="sm" show={isShow} onHide={handleHide} onExited={handleClose} role="success-modal">
      <Modal.Body className="modal-colored-header bg-success text-center">
        <i className="uil-check h1" />
        <h4 className="mt-2">{title || 'Sukces!'}</h4>
        <p className="mt-3 text-semi-strong">{message || 'Zmiany zostały zapisane'}</p>
        <button type="button" className="btn btn-light my-2" data-dismiss="modal" onClick={handleHide}>
          {buttonText || 'OK'}
        </button>
      </Modal.Body>
    </Modal>
  )
}
