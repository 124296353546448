import * as React from 'react'
import { Button } from '@components/button'
import { IconWithText } from '@components/icon-with-text'
import { useNavigate } from 'react-router-dom'

export const ReturnButton = (): JSX.Element => {
  const navigate = useNavigate()
  const onReturn = () => navigate(-1)

  return (
    <Button
      className="border-medium px-4 py-3 py-lg-2 d-block font-size-12 fw-bold col-md-auto col-5 btn-outline-light bg-white border"
      onClick={onReturn}
    >
      <IconWithText text="Powrót" icon="uil-angle-left font-size-20" />
    </Button>
  )
}
