import * as React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { getCategoryUrl } from '@components/sidebar/sidebar-categories-row'
import { Category } from '@models/products'

interface Props {
  id: string
  categories: Category[]
}

export const SidebarCategoriesRowChildren = ({ id, categories }: Props): JSX.Element => (
  <div className="collapse border-bottom" id={id}>
    <ul>
      {categories.map((category, index) => (
        <li className={classnames('ps-2 font-size-12 py-2', { 'border-top': index >= 1 })} key={category.id}>
          <Link className="sidebar__categories__link" to={getCategoryUrl(category)}>
            {category.name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
)
