import * as React from 'react'
import Decimal from 'decimal.js'

function setDocumentTitle(title) {
  document.title = `${title} | Dashboard`
}

function getDocumentTitle() {
  return document.title
}

export const useDocumentTitle = (title: string) => {
  React.useEffect(() => {
    const currentTitle = getDocumentTitle()
    setDocumentTitle(title)

    return () => setDocumentTitle(currentTitle)
  }, [title])
}

export const formatPrice = (value: number | string) =>
  Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  }).format(typeof value === 'string' ? parseFloat(value) : value)

export const sumDecimalArray = (decimals: Decimal[]): Decimal =>
  decimals.reduce((prev: Decimal, current: Decimal) => prev.add(current), new Decimal(0))

export const formatNumberWithThousandSeparator = (value: number | string) =>
  String(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
