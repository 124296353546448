import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { CartSecondStepFormInputs } from '@modules/shop/cart/cart-second-step/index'
import { InputErrorMessage } from '@components/controls/input-error-message'
import classNames from 'classnames'

export const RulesCartSecondStepRules = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CartSecondStepFormInputs>()
  return (
    <>
      <p className="text-success fw-bold font-size-14">Zaakceptuj warunki zamówienia</p>
      <div className="form-check my-4">
        <input
          className={classNames('form-check-input border-green-light', {
            'is-invalid': !!errors['rules'],
          })}
          type="checkbox"
          value="true"
          id="rules"
          {...register('rules')}
        />
        <label className="form-check-label font-size-12" htmlFor="rules">
          * Oświadczam, że znam i akceptuję postanowienia Regulaminu Sklepu Internetowego DobreNasiona.pl
        </label>
        <InputErrorMessage name="rules" />
      </div>
    </>
  )
}
