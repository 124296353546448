import * as React from 'react'
import { useParams } from 'react-router-dom'
import { PageLayout } from '@components/page-layout'
import { BlogSidebar } from '@modules/blog/blog-sidebar'

type Params = 'id'

export const BlogArticleView = () => {
  const { id } = useParams<Params>()

  return (
    <PageLayout sidebar={<BlogSidebar />}>
      <div>
        <p className="text-success fw-bold font-size-22">{id}</p>
        <p>Treść artykułu</p>
      </div>
    </PageLayout>
  )
}
