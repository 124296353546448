import * as React from 'react'

const getNumber = (value: string | number) => (typeof value === 'string' ? parseInt(value, 10) : value)

interface Response {
  getNumberValue: (value: string | number) => number
}

export const useInputNumberValue = (inputProps: React.HTMLProps<HTMLInputElement>): Response => {
  const getNumberValue = (value: string | number) => {
    const inputValue = getNumber(value)
    if (inputProps.max && inputValue > inputProps.max) return getNumber(inputProps.max)
    if (inputProps.min && inputValue < inputProps.min) return getNumber(inputProps.min)

    return inputValue
  }

  return { getNumberValue }
}
