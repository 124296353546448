import * as React from 'react'
import { Product } from '@models/products'

interface Props {
  product: Product
}

export const CartItem = ({ product }: Props): JSX.Element => (
  <div className="d-flex gap-3 my-2 align-items-center">
    {product.main_image && <img src={product.main_image?.thumbnail} alt="" width={50} />}
    <p className="fw-semi-bold">{product.name}</p>
  </div>
)
