import * as React from 'react'
import { CustomCarousel } from '@components/carousel/custom-carousel'

interface Props {
  images: any
}

export const HomeSeedsGallerySection = ({ images }: Props): JSX.Element => (
  <div>
    <p className="text-success fw-bold font-size-22 mb-4">Galeria nasion</p>
    <div className="home__gallery">
      <CustomCarousel
        initialCount={5}
        nextButtonClass="home__gallery__next-button"
        previousButtonClass="home__gallery__previous-button"
      >
        {images.map(image => (
          <img key={image} src={require(`@assets/images/${image}`)} className="mx-3 home__gallery__image" />
        ))}
      </CustomCarousel>
    </div>
  </div>
)
