import * as React from 'react'
import Carousel, { CarouselProps } from 'react-multi-carousel'
import { CarouselControls } from '@components/carousel/carousel-controls'

interface Props extends Omit<CarouselProps, 'responsive'> {
  children: React.ReactNode
  nextButtonClass?: string
  previousButtonClass?: string
  initialCount?: number
}

export const CustomCarousel = ({ children, nextButtonClass, previousButtonClass, initialCount, ...props }: Props) => (
  <Carousel
    arrows={false}
    responsive={responsive({ initialCount })}
    swipeable
    renderButtonGroupOutside
    customButtonGroup={<CarouselControls nextButtonClass={nextButtonClass} previousButtonClass={previousButtonClass} />}
    {...props}
  >
    {children}
  </Carousel>
)

const responsive = ({ initialCount = 4 }) => ({
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: initialCount,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
})
