import * as React from 'react'
import classNames from 'classnames'

interface Props {
  titleClassName?: string
}

export const SidebarContact = ({ titleClassName }: Props): JSX.Element => (
  <div className="p-0">
    <p className={classNames('mb-4', titleClassName)}>Krzysztof Baran</p>
    <div className="ms-lg-n5">
      <div className="d-flex align-items-start gap-3 pb-1">
        <i className="uil-map-marker text-success font-size-30" />
        <div>
          <span className="d-block">Ul. Główna 4 A </span>
          <span className="d-block">44-144 Nieborowice</span>
          <span>NIP: 969-133-07-32</span>
        </div>
      </div>

      <div className="d-flex align-items-center gap-3 py-1">
        <i className="uil-envelope text-success font-size-28" />
        <a href="mailto:kontakt@dobrenasiona.pl" className="text-warning">
          kontakt@dobrenasiona.pl
        </a>
      </div>

      <div className="d-flex align-items-center gap-3 py-1">
        <i className="uil-phone text-success font-size-28" />
        <a href="tel:+48602353022">(+48) 602 353 022</a>
      </div>

      <div className="d-flex align-items-center gap-3">
        <img src={require('@assets/images/icons/piorin.svg')} alt="" height={65} width={30} />
        <div>Nr PIORiN: PL-24/66/7059</div>
      </div>
    </div>
  </div>
)
