import * as React from 'react'
import { OrderDetails } from '@models/order'
import { CartItem } from '@modules/shop/cart/cart-item'
import { formatPrice } from '@helpers/utils'
import { RulesCartSecondStepSummaryDelivery } from '@modules/shop/cart/cart-second-step/summary-deliver'

interface Props {
  basketDetails: OrderDetails
}

export const RulesCartSecondStepSummary = ({ basketDetails }: Props): JSX.Element => (
  <>
    <p className="text-success fw-semi-bold font-size-16">Podsumowanie Zakupów</p>
    {basketDetails.items.map(item => (
      <CartItem key={item.id} product={item.product} />
    ))}
    <RulesCartSecondStepSummaryDelivery />
    <div className="d-flex gap-2 align-items-end fw-bold">
      <p className="font-size-12 pb-1">Razem</p>
      <p className="text-warning fw-bold font-size-22"> {formatPrice(basketDetails?.total_price_brutto || 0)}</p>
    </div>
  </>
)
