import * as React from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { categoriesSelector } from '@store/slices/products-slice'
import { Link } from 'react-router-dom'
import { getCategoryUrl } from '@components/sidebar/sidebar-categories-row'

interface Props {
  isVisible: boolean
  onHide: () => void
}

export const CategoriesMenu = ({ isVisible, onHide }: Props): JSX.Element => {
  const allCategories = useSelector(categoriesSelector)
  const categoriesWithoutParent = React.useMemo(() => allCategories.filter(row => !row.parent_id), [allCategories])

  const getSubCategory = category => allCategories.filter(row => row.parent_id === category.id)

  return (
    <section className={classnames('mobile-menu', { 'is-visible': isVisible })}>
      <i className="uil-angle-left mobile-menu__close" onClick={onHide} />
      <img
        src={require('@assets/images/main-logo.svg')}
        alt="Dobrenasiona.pl logo"
        width={200}
        className="mobile-menu__logo"
      />
      <div className="mobile-menu__content">
        <ul>
          {categoriesWithoutParent.map(category => (
            <li key={category.id} className="mt-0">
              <Link className="mobile-menu__content__link" to={getCategoryUrl(category)}>
                {category.name}
              </Link>
              <ul>
                {getSubCategory(category).map(subCategory => (
                  <li key={subCategory.id} className="my-0 pb-0">
                    <Link className="mobile-menu__content__link" to={getCategoryUrl(subCategory)}>
                      {subCategory.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
