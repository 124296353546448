import * as React from 'react'
import { ProgressBar } from 'react-bootstrap'
import classnames from 'classnames'

const progressFill = {
  1: 0,
  2: 50,
  3: 100,
}

interface Props {
  step: string
}
export const CartStepProgress = ({ step }: Props): JSX.Element => {
  const stepValue =
    {
      summary: 1,
      params: 2,
      confirmed: 3,
    }[step] || 1

  return (
    <div className="progress__wrapper">
      <ProgressBar now={progressFill[step]} variant="warning cart-progress" style={{ height: '7px' }} />
      <div className={classnames('progress__item start-0 cursor-pointer', { 'is-completed': stepValue >= 1 })}>1</div>
      <div
        className={classnames('progress__item progress__item__circle__second cursor-pointer', {
          'is-completed': stepValue >= 2,
        })}
      >
        2
      </div>
      <div className={classnames('progress__item end-0', { 'is-completed': stepValue >= 3 })}>3</div>

      <div className="progress__item__text is-first">Twój koszyk</div>
      <div className="progress__item__text is-second">Dostawa i płatność</div>
      <div className="progress__item__text is-third">Potwierdzenie</div>
    </div>
  )
}
