import * as React from 'react'
import { HomeStatement } from '@modules/home/home-statement'
import { Button } from '@components/button'

export const HomeWelcomeSection = (): JSX.Element => (
  <div>
    <div className="d-flex align-items-center">
      <div className="col-lg-6 col-12">
        <h2 className="text-warning fw-bold mt-2 mt-lg-0">PIĘKNO WYRASTA Z ZIEMI</h2>
        <span className="d-block font-size-22 my-2">Witamy serdecznie w naszym sklepie Internetowym z nasionami!</span>
        <span>Aktualnie w naszej ofercie znajduje się ponad 300 produktów.</span>
        <Button className="border-medium mt-lg-4 mt-3 px-5 py-lg-2 py-3 d-block d-lg-inline">Sprawdź</Button>
      </div>
      <img src={require('@assets/images/welcome-image.svg')} alt="dobre-nasiona-logo" className="d-lg-block d-none" />
    </div>
    <div className="d-flex justify-content-between flex-column flex-lg-row mt-4 mt-lg-0">
      <HomeStatement title="Darmowa Wysyłka" message="zamówienie powyżej 100zł" icon="delivery-icon.svg" />
      <HomeStatement title="Porady ogrodnicze" message="w godzinach: 15-20" icon="advice-icon.svg" />
      <HomeStatement title="Kupony rabatowe" message="przy większym zamówieniu" icon="discount-icon.svg" />
    </div>
  </div>
)
