import * as React from 'react'
import { useAppSelector } from '@store/hooks'
import { CartPurchasesTableRow } from '@modules/shop/cart/cart-purchases-table-row'
import { basketDetailsSelector } from '@store/slices/basket-slice'

export const CartPurchasesTable = () => {
  const basketDetails = useAppSelector(basketDetailsSelector)

  return (
    <table className="table w-100 ">
      <colgroup>
        <col width="5%" />
        <col width="10%" />
        <col width="55%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
      </colgroup>
      <thead className="border-top-0 fw-semi-bold">
        <tr>
          <th className="d-none d-md-table-cell" colSpan={2} />
          <th className="d-none d-md-table-cell">Produkt</th>
          <th className="d-none d-md-table-cell text-end">Cena</th>
          <th className="d-none d-md-table-cell text-end">Ilość</th>
          <th className="d-none d-md-table-cell text-end">Suma</th>
        </tr>
      </thead>
      <tbody className="border-top-0 fw-semi-bold">
        {basketDetails?.items?.length ? (
          basketDetails.items.map(item => <CartPurchasesTableRow key={item.id} item={item} />)
        ) : (
          <tr>
            <td colSpan={6} className="text-center">
              Brak produktów w koszyku
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
