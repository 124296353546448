import * as React from 'react'
import { useFormContext } from 'react-hook-form'

interface Props {
  name: string
}

export const InputErrorMessage = ({ name }: Props): JSX.Element | null => {
  const {
    formState: { errors },
  } = useFormContext()

  const errorMessage = errors[name]

  return errorMessage ? <div className="invalid-feedback d-block">{String(errorMessage.message)}</div> : null
}
