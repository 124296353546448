import * as React from 'react'

import { format, intervalToDuration, parseISO } from 'date-fns'
import { pl } from 'date-fns/locale'

export const formatDate = (date: Date | undefined | string | null, dateFormat = 'yyyy-MM-dd'): string => {
  if (date === null) {
    return ''
  }
  if (typeof date === 'string') {
    return format(parseISO(date), dateFormat, { locale: pl })
  }
  if (typeof date !== 'undefined') {
    return format(date, dateFormat, { locale: pl })
  }
  return date ? date : ''
}

export const parseISODate = (value: string | Date | null | undefined): Date | null => {
  if (!value) {
    return null
  }
  if (typeof value === 'string') {
    return parseISO(value)
  }
  return value
}

export const defaultFrontendDate = 'dd.MM.yyyy'
export const defaultFrontendDateTime = 'dd.MM.yyyy HH:mm'
export const defaultFrontendTime = 'HH:mm'

export const toDefaultDateTimeFormatWithBrake = (value: string | Date | null) => {
  const date = parseISODate(value)
  return (
    <>
      {toDefaultDateFormat(date)}
      <br />
      {toDefaultTimeFormat(date)}
    </>
  )
}

export const toTextDateTimeFormat = (value: string | Date | null) =>
  formatDate(parseISODate(value), 'd LLLL RRRR') + ' godz.: ' + toDefaultTimeFormat(value)

export const toDefaultDateTimeFormat = (value: string | Date | null) =>
  formatDate(parseISODate(value), defaultFrontendDateTime)

export const toDefaultDateFormat = (value: string | Date | null | undefined) =>
  formatDate(parseISODate(value || null), defaultFrontendDate)

export const toDefaultTimeFormat = (value: string | Date | null | undefined) =>
  formatDate(parseISODate(value || null), defaultFrontendTime)

export const secondsToInterval = seconds => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  const hours = (duration?.hours || 0) + (duration?.days || 0) * 24

  return `${String(hours).padStart(2, '0')}:${String(duration.minutes).padStart(2, '0')}:${String(
    duration.seconds,
  ).padStart(2, '0')}`
}
