import * as React from 'react'
import { CarouselNextButton } from '@components/carousel/carousel-next-button'
import { CarouselPreviousButton } from '@components/carousel/carousel-previous-button'

export const CarouselControls = ({ nextButtonClass, previousButtonClass, ...props }) => (
  <>
    <CarouselNextButton className={nextButtonClass} onClick={props.next} />
    <CarouselPreviousButton className={previousButtonClass} onClick={props.previous} />
  </>
)
