import * as React from 'react'
import { ReturnButton } from '@components/return-button'
import { Button } from '@components/button'

interface Props {
  isLoading: boolean
}

export const CartSecondStepOrder: React.FC<Props> = ({ isLoading }) => (
  <div className="d-flex">
    <ReturnButton />
    <Button type="submit" disabled={isLoading} className="border-medium w-lg-75 py-3 fw-bold font-size-12 ms-auto">
      {isLoading ? 'Zamawianie...' : 'Kupuję i płacę'}
    </Button>
  </div>
)
