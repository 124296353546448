import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { PaginationResponse } from '@models/base'
import { Product } from '@models/products'
import { RootState } from '@store/types'

export const PRODUCTS_TAG = 'products'
export interface ProductsFilterParams {
  search?: string
  category_id?: string
  page: number
  page_size: string
  ordering: string
  price_brutto: [number, number] | undefined
  destinations?: string[]
  producers?: string[]
}
export const productsAPI = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'products',
  tagTypes: [PRODUCTS_TAG],
  endpoints: builder => ({
    getProduct: builder.query<Product[], ProductsFilterParams>({
      queryFn: apiQueryFunction((state: RootState, params) => ({
        method: 'GET',
        params,
        url: state.websiteState.appData.urls.products.products,
        responseTransformer: (response: PaginationResponse<Product>) => response.results,
      })),
      providesTags: (result: Product[]) =>
        result ? [...result.map(({ id }) => ({ type: PRODUCTS_TAG, id } as const)), PRODUCTS_TAG] : [PRODUCTS_TAG],
    }),
  }),
})

export const { useGetProductQuery } = productsAPI
