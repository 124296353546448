import 'core-js/stable'
import '@iconscout/unicons/css/line.css'
import '@assets/scss/app.scss'

import store from '@store/index'
import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import StartupView from './startup'
import { AppRoutes } from '@routes/index'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import TopBarProgress from 'react-topbar-progress-indicator'
import * as Qs from 'query-string'

import 'bootstrap/js/dist/collapse'

import axios from 'axios'
import { formatDate } from '@helpers/date-helper'
import { NotificationHandler } from '@components/notification-service'

axios.defaults.headers.get['Accept'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.put['Accept'] = 'application/json'
axios.defaults.headers.patch['Accept'] = 'application/json'
axios.defaults.headers.delete['Accept'] = 'application/json'
axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    const payload = {}
    Object.entries(params).map(([key, value]) => {
      if (value instanceof Date) {
        payload[key] = formatDate(value, 'yyyy-MM-dd HH:mm')
      } else {
        payload[key] = value
      }
    })
    return Qs.stringify(payload)
  }

  return config
})

TopBarProgress?.config({
  barThickness: 7,
  barColors: { '0': 'rgba(198, 174, 126, 1)', '1.0': 'rgba(148, 118, 72, 1)' },
  shadowBlur: 5,
})

const AppContainer = (props: React.PropsWithChildren<any>): JSX.Element => {
  const location = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return props.children
}

if (process.env.SHOP_FRONTEND_SENTRY_JAVASCRIPT_DSN) {
  Sentry.init({
    dsn: process.env.SHOP_FRONTEND_SENTRY_JAVASCRIPT_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.005,
  })
}

export default () => (
  <Provider store={store}>
    <StartupView>
      <BrowserRouter basename="/">
        <AppContainer>
          <NotificationHandler />
          <AppRoutes />
        </AppContainer>
      </BrowserRouter>
    </StartupView>
  </Provider>
)
