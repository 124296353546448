import * as React from 'react'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { Button } from '@components/button'
import { formatPrice } from '@helpers/utils'
import { getBasketDetails } from '@store/actions/basket-actions'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { basketDetailsSelector } from '@store/slices/basket-slice'

export const HeaderBasket = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const basketDetails = useAppSelector(basketDetailsSelector)

  React.useEffect(() => {
    dispatch(getBasketDetails())
  }, [])

  const totalItems = basketDetails.items?.map(row => row.quantity).reduce((prev, curr) => prev + curr, 0)

  return (
    <Link to={NavigationPath.Cart}>
      <Button variant="secondary" className="border-large align-items-center d-flex px-lg-4 px-2 position-relative">
        <i className="uil-shopping-cart-alt text-warning font-size-28" />
        <span className="cart__badge bg-success rounded-shape ">{totalItems || 0}</span>
        <span className="ms-4 me-2 text-warning fw-semi-bold">{formatPrice(basketDetails?.price_brutto || 0)}</span>
      </Button>
    </Link>
  )
}
