import { Category, Product, ProductDetails } from '@models/products'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { EntityState } from '@reduxjs/toolkit/src/entities/models'
import { getCategories, getProductDetails } from '@store/actions/products-actions'

import { RootState } from '@store/types'

export interface ProductsState {
  products: EntityState<Product>
  categories: EntityState<Category>
  productDetails: ProductDetails | null
}

const productsAdapter = createEntityAdapter<Product>()
const categoriesAdapter = createEntityAdapter<Category>()

const initialState: ProductsState = {
  productDetails: null,
  products: productsAdapter.getInitialState(),
  categories: categoriesAdapter.getInitialState(),
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      categoriesAdapter.setAll(state.categories, action.payload)
    })

    builder.addCase(getProductDetails.pending, state => {
      state.productDetails = null
    })
    builder.addCase(getProductDetails.fulfilled, (state, action) => {
      state.productDetails = action.payload
    })
  },
})

export const { selectAll: categoriesSelector, selectById: categorySelectByIdSelector } = categoriesAdapter.getSelectors(
  (state: RootState) => state.productsState.categories,
)

export const productDetailsSelector = (state: RootState) => state.productsState.productDetails

export default productsSlice.reducer
