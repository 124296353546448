import * as React from 'react'
import { useHandleAxiosFormErrors } from '@helpers/handle-axios-errors'
import { useNotificationHook } from './use-notification-hook'

type state = 'pending' | 'resolved' | 'rejected' | 'idle'

export const useApiRequest = <T>(asyncCallable) => {
  const [state, setState] = React.useState<state>('idle')
  const [data, setData] = React.useState<T | undefined>(undefined)
  const { addErrorMessage } = useNotificationHook()

  const action = async (...params) => {
    setState('pending')
    try {
      const result = await asyncCallable(...params)
      setData(result)
      setState('resolved')
      return result
    } catch (error) {
      console.warn(error)
      if (error.response?.status === 403) {
        addErrorMessage('Błąd', 'Brak uprawnień do wykonania tej akcji')
      } else if (error.response?.status === 400) {
        if (error.response.data.non_field_errors) {
          addErrorMessage('Błąd', error.response.data.non_field_errors.join('. '))
        }
      } else {
        addErrorMessage('Błąd', 'Wystąpił błąd podczas pobierania danych')
      }
      setState('rejected')
    }
  }

  return {
    state,
    action,
    data,
    isLoading: state === 'pending',
  }
}

export const useFormRequest = <T>(asyncCallable, setError) => {
  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  return useApiRequest<T>(async (...params) => {
    try {
      return await asyncCallable(...params)
    } catch (error) {
      handleAxiosFormErrors(error, setError, { showGlobalError: false })
    }
  })
}
