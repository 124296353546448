import * as React from 'react'
import { Button } from '@components/button'
import { formatPrice } from '@helpers/utils'
import { CartSecondStepDeliveryMethodInpost } from '@modules/shop/cart/cart-second-step/delivery-method-inpost'
import { useFormContext, useWatch } from 'react-hook-form'
import { commonObjectGet, commonObjectPatch } from '@store/actions/generic-actions'
import { TransportOption } from '@models/shop'
import { ContentLoader } from '@components/content-loader'
import { InputErrorMessage } from '@components/controls/input-error-message'
import { CartSecondStepFormInputs } from '@modules/shop/cart/cart-second-step/index'
import { updateBasketDetails } from '@store/slices/basket-slice'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useApiRequest } from '@hooks/use-api-request'
import { useAppData } from '@hooks/use-app-data'
import { RootState } from '@store/types'

export const CartSecondStepDeliveryMethod = (): JSX.Element => {
  const [selectedTransportOption, setSelectedTransportOption] = React.useState<TransportOption>()
  const [transportOptions, setTransportOptions] = React.useState<TransportOption[]>([])
  const { setValue, clearErrors, control } = useFormContext<CartSecondStepFormInputs>()

  const paymentMethod = useWatch({
    name: 'payment_method',
    control,
  })
  const { urls } = useAppData()

  const { isLoading, action: fetch } = useApiRequest(async () =>
    setTransportOptions(await commonObjectGet<TransportOption[]>(urls.shop.basket_transport_options)),
  )

  const handleDeliveryChange = (deliveryType: TransportOption) => () => setSelectedTransportOption(deliveryType)
  const orderUrl = useAppSelector((state: RootState) => state.websiteState.appData.urls.shop.basket_details)
  const dispatch = useAppDispatch()

  const { action: updateTransport } = useApiRequest(async (transport_option: TransportOption) =>
    dispatch(updateBasketDetails(await commonObjectPatch(orderUrl, { transport_option: transport_option.id }))),
  )

  React.useEffect(() => {
    setValue('transport_option_context', undefined)
    if (selectedTransportOption) {
      updateTransport(selectedTransportOption)
      setValue('transport_option', selectedTransportOption)
    }
    clearErrors('transport_option')
  }, [selectedTransportOption])

  React.useEffect(() => {
    setValue('transport_option_context', undefined)
    setValue('transport_option', undefined)
    setSelectedTransportOption(undefined)
  }, [paymentMethod])

  React.useEffect(() => {
    fetch()
  }, [])

  const filteredTransportOptions = transportOptions.filter(row => row.payment_method === paymentMethod)

  return (
    <ContentLoader isLoading={isLoading} style={{ minHeight: 150 }}>
      <p className="text-success fw-bold font-size-14">Wybierz sposób dostawy</p>
      <InputErrorMessage name="transport_option" />
      <div className="my-4 d-flex flex-lg-nowrap flex-wrap">
        {filteredTransportOptions.map(transportOption => (
          <Button
            key={transportOption.name}
            type="button"
            className="font-size-12 border-medium py-2 px-3 fw-bold m-1"
            onClick={handleDeliveryChange(transportOption)}
            variant={transportOption.id === selectedTransportOption?.id ? 'primary' : 'tertiary'}
          >
            {transportOption.name} <br />
            {formatPrice(transportOption.price_brutto)}
          </Button>
        ))}
      </div>
      {selectedTransportOption?.type === 'inpost_point' && <CartSecondStepDeliveryMethodInpost />}
    </ContentLoader>
  )
}
