import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { Input } from '@components/controls/input'
import { OrderItem } from '@models/order'
import { removeBasketItem, updateBasketItem } from '@store/actions/basket-actions'
import { useAppDispatch } from '@store/hooks'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useDebounce, useDidUpdate } from 'rooks'
import { useNotificationHook } from '@hooks/use-notification-hook'
import { useApiRequest } from '@hooks/use-api-request'

interface Props {
  item: OrderItem
}

interface FormInputs {
  quantity: number
}

export const CartPurchasesTableRow = ({ item }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { action: handleDelete } = useApiRequest(async () => {
    await dispatch(removeBasketItem({ product: item.product }))
    addSuccessNotification('Produkt został usunięty z koszyka')
  })

  const { ...methods } = useForm<FormInputs>({
    defaultValues: {
      quantity: item.quantity,
    },
  })

  const newQuantity = useWatch<FormInputs>({
    name: 'quantity',
    control: methods.control,
  })

  const { action: handleChange } = useApiRequest(async quantity => {
    await dispatch(updateBasketItem({ product: item.product, quantity }))
    addSuccessNotification('Produkt został zmieniony w koszyku')
  })

  const debouncedChange = useDebounce(handleChange, 500)
  const memoizedChange = React.useCallback(debouncedChange, [])

  useDidUpdate(() => {
    memoizedChange(newQuantity)
  }, [newQuantity])

  return (
    <FormProvider {...methods}>
      <tr className="cart__purchases-table__row">
        <td>
          <strong className="cursor-pointer" onClick={handleDelete}>
            x
          </strong>
        </td>
        <td>
          {item.product.main_image && (
            <img src={item.product.main_image.thumbnail} alt={item.product.name} className="h-100" />
          )}
        </td>
        <td className="align-middle">{item.product.name}</td>
        <td className="d-md-table-cell d-none text-end">{formatPrice(item.price_brutto)}</td>
        <td className="d-md-table-cell d-none ">
          <Input
            onChange={handleChange}
            className="cart__purchases-table__qty"
            type="number"
            name="quantity"
            min={1}
            max={99}
          />
        </td>
        <td className="d-md-table-cell d-none text-end">{formatPrice(item.total_price_brutto)}</td>
      </tr>
      <tr className="d-md-none">
        <td colSpan={5}>
          <div className="row mt-2 d-md-none">
            <div className="col-4 text-center">
              <p className="fw-bold mb-1">Cena</p>
              <strong className="mt-4 d-block">{formatPrice(item.price_brutto)}</strong>
            </div>
            <div className="col-4 text-center d-flex justify-content-center flex-column align-items-center">
              <p className="fw-bold mb-1">Ilość</p>
              <Input
                onChange={handleChange}
                className="cart__purchases-table__qty"
                type="number"
                name="quantity"
                defaultValue={item.quantity}
                min={1}
                max={99}
              />
            </div>
            <div className="col-4 text-center">
              <p className="fw-bold mb-1">Suma</p>
              <strong className="mt-4 d-block">{formatPrice(item.total_price_brutto)}</strong>
            </div>
          </div>
        </td>
      </tr>
    </FormProvider>
  )
}
