import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CartSecondStepFormInputs } from '@modules/shop/cart/cart-second-step/index'

export const RulesCartSecondStepSummaryDelivery = (): JSX.Element | null => {
  const { control } = useFormContext<CartSecondStepFormInputs>()

  const [transport_option, payment_method] = useWatch({ name: ['transport_option', 'payment_method'], control })

  if (!transport_option) {
    return null
  }

  return (
    <>
      <p className="text-success fw-semi-bold mt-2 font-size-16">Wysyłka</p>
      <div className="my-2">
        <p className="fw-semi-bold">
          {transport_option.name}
          {payment_method === 'cash_on_delivery' ? ' (Pobranie)' : ' (Płatne z góry)'}
        </p>
      </div>
    </>
  )
}
