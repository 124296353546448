import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from '@api/base'
import { RootState } from '@store/types'
import { PageDetails } from '@models/pages'

export const PAGES_TAG = 'pages'

export const pagesAPI = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'pages',
  tagTypes: [PAGES_TAG],
  endpoints: builder => ({
    getPage: builder.query<PageDetails, string>({
      queryFn: apiQueryFunction((state: RootState, slug) => ({
        method: 'GET',
        url: state.websiteState.appData.urls.pages.page_details.replace('replace-me', slug),
      })),
      providesTags: (result: PageDetails) =>
        result ? [{ type: PAGES_TAG, id: result.slug } as const, PAGES_TAG] : [PAGES_TAG],
    }),
  }),
})

export const { useGetPageQuery } = pagesAPI
