import { toast } from 'react-toastify'
import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { NotificationMessage, Variant } from '@components/notification-service'
import {
  addNotificationMessage,
  notificationMessagesSelector,
  removeNotificationMessage,
} from '@store/slices/notification-slice'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { ToastOptions } from 'react-toastify/dist/types'

export const buildErrorMessage = (
  title = 'Błąd',
  message = 'Przepraszamy wystąpił błąd, spróbuj ponownie później.',
) => ({
  title,
  message,
  variant: Variant.Error,
  id: uuidv4(),
})

const buildToast = (message, icon, role): [React.ReactNode, ToastOptions] => [
  <>
    <i className={`${icon} font-14`} /> {message}
  </>,
  {
    role: role,
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
  },
]

export const useNotificationHook = () => {
  const messages = useAppSelector(notificationMessagesSelector)
  const dispatch = useAppDispatch()

  const addMessage = (message: NotificationMessage) => dispatch(addNotificationMessage(message))

  const addErrorMessage = (title = 'Błąd', message = 'Przepraszamy wystąpił błąd, spróbuj ponownie później.') =>
    addMessage(buildErrorMessage(title, message))

  const addSuccessMessage = (title = 'Sukces', message = 'Zmiany zostały zapisane pomyślnie!') =>
    addMessage({
      title,
      message,
      variant: Variant.Success,
      id: uuidv4(),
    })

  const addSuccessNotification = (message: string) => {
    const [content, options] = buildToast(message, 'uil-check', 'success-modal')
    return toast.success(content, options)
  }

  const addErrorNotification = (message: string) => {
    const [content, options] = buildToast(message, 'uil-ban', 'error-modal')

    toast.error(content, options)
  }

  const addInfoNotification = (message: string) => {
    const [content, options] = buildToast(message, 'uil-info-circle', 'info-modal')

    toast.info(content, options)
  }

  const removeMessage = (messageToRemove: NotificationMessage) => dispatch(removeNotificationMessage(messageToRemove))

  return {
    removeMessage,
    addInfoNotification,
    addErrorNotification,
    addSuccessNotification,
    addSuccessMessage,
    addErrorMessage,
    messages,
  }
}
