import * as React from 'react'
import classNames from 'classnames'

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  variant?: ButtonVariant
}

export const Button = ({ children, variant = 'primary', ...props }: Props): JSX.Element => (
  <button {...props} className={classNames('btn fw-semi-bold', props.className, variantStyles[variant])}>
    {children}
  </button>
)

const variantStyles = {
  primary: 'text-white bg-warning',
  secondary: 'text-warning bg-orange-light',
  tertiary: 'text-primary bg-light-gray',
}
