import * as React from 'react'
import { Link } from 'react-router-dom'
import { Category } from '@models/products'
import { NavigationPath } from '@models/routes'
import { useSelector } from 'react-redux'
import { categoriesSelector } from '@store/slices/products-slice'
import { SidebarCategoriesRowChildren } from '@components/sidebar/sidebar-categories-row-children'

interface Props {
  category: Category
}

const collapseId = category => `collapse-category-${category.id}`

export const getCategoryUrl = category =>
  NavigationPath.CategoryDetails.replace(':slug', category.slug).replace(':id', category.id)

export const SidebarCategoriesRow = ({ category }: Props): JSX.Element => {
  const categories = useSelector(categoriesSelector)

  const children = categories.filter(row => row.parent_id === category.id)

  return (
    <>
      <li className="ps-2 font-size-12 py-2 sidebar__categories__item cursor-pointer" key={category.id}>
        <span className="d-flex align-items-center w-100 justify-content-between">
          <Link className="sidebar__categories__link" to={getCategoryUrl(category)}>
            {category.name}
          </Link>
          {!!children.length && (
            <span
              className="uil-angle-right font-size-20 text-warning collapse-icon lh-1"
              data-bs-toggle="collapse"
              data-bs-target={`#${collapseId(category)}`}
            />
          )}
        </span>
      </li>
      <SidebarCategoriesRowChildren id={collapseId(category)} categories={children} />
    </>
  )
}
