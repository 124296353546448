import * as React from 'react'

interface Props {
  title: string
  children: React.ReactElement[] | React.ReactElement
}

export const FooterSection = ({ title, children }: Props): JSX.Element => (
  <div className="mb-3 mb-sm-0 col-sm-6 col-12 col-lg-4 mx-auto">
    <p className="fw-bold mb-sm-4 mb-2 ">{title}</p>
    {children}
  </div>
)
