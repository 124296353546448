import * as React from 'react'

export const useNoScrollableBody = (shouldHideScrollbar = true): void => {
  const [scrollHeight, setScrollValue] = React.useState(document.documentElement.scrollTop)

  React.useEffect(() => {
    if (shouldHideScrollbar) {
      setScrollValue(document.documentElement.scrollTop)

      document.body.classList.add('block-scroll')
    }

    if (!shouldHideScrollbar && scrollHeight) {
      window.scroll({ top: scrollHeight, behavior: 'auto' })
      setScrollValue(0)
    }

    return () => {
      if (!shouldHideScrollbar && scrollHeight) {
        window.scroll({ top: scrollHeight, behavior: 'auto' })
        setScrollValue(0)
      }

      document.body.classList.remove('block-scroll')
    }
  }, [shouldHideScrollbar])
}
