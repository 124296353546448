import * as React from 'react'
import { SearchBox } from '@components/search-box'
import { Button } from '@components/button'
import { IconWithText } from '@components/icon-with-text'
import { NavigationPath } from '@models/routes'
import { HeaderLink } from '@components/header/header-link'
import { Link } from 'react-router-dom'
import { HeaderBasket } from '@components/header/header-basket'
import { HamburgerMenu } from '@components/header/hamburger-menu'

export const Header = (): JSX.Element => (
  <div className="container py-3">
    <div className="d-flex justify-content-between">
      <div className="justify-content-between d-none d-lg-flex col-12 col-sm-4">
        <a href="tel:+48602353022">
          <IconWithText
            icon="uil-phone"
            text="(+48) 602 353 022"
            textClassName="text-warning mx-2 text-nowrap"
            width={15}
            iconClassName="text-success font-size-16"
          />
        </a>
        <a href="mailto:kontakt@dobrenasiona.pl">
          <IconWithText
            icon="uil-envelope"
            text="kontakt@dobrenasiona.pl"
            textClassName="text-warning mx-2"
            width={15}
            iconClassName="text-success font-size-16"
          />
        </a>
      </div>
      {/*<div className="d-flex justify-content-between col-lg-4 col-12">*/}
      {/*  <HeaderLink destination={NavigationPath.About} text="Moje Konto" />*/}
      {/*  <HeaderLink destination={NavigationPath.About} text="Zarejestruj się" />*/}
      {/*  <HeaderLink destination={NavigationPath.About} text="Zaloguj się" />*/}
      {/*</div>*/}
    </div>
    <div className="d-flex align-items-center justify-content-between pt-3 flex-wrap">
      <Link to={NavigationPath.Home} className="col-lg-auto col-12 text-center mb-5 mt-3 mt-lg-0 mb-lg-0">
        <img src={require('@assets/images/main-logo.svg')} alt="" />
      </Link>
      <HamburgerMenu />
      <SearchBox />

      <HeaderLink destination="/strona/o-firmie/" text="O nas" className="d-lg-block d-none" />
      <HeaderLink destination={NavigationPath.Blog} text="Blog" className="d-lg-block d-none" />
      <HeaderLink destination={NavigationPath.Contact} text="Kontakt" className="d-lg-block d-none" />
      <Button className="border-small border-2 border-warning p-1 bg-transparent d-lg-block d-none" variant="secondary">
        <i className="uil-facebook-f text-warning font-size-20" />
      </Button>

      <HeaderBasket />
    </div>
  </div>
)
