import * as React from 'react'
import { useNoScrollableBody } from '@hooks/use-no-scrollable-body'
import classnames from 'classnames'
import { useDeviceSize } from '@hooks/use-device-size'
import ReactDOM from 'react-dom'

interface Props {
  isOpen: boolean
  toggleIsOpen: () => void
  children: React.ReactNode
}

export const MobileModalView = ({ isOpen, toggleIsOpen, children }: Props): JSX.Element | null => {
  useNoScrollableBody(isOpen)
  const { isDesktop } = useDeviceSize()

  if (isDesktop) return null

  const handleClose = event => {
    event.preventDefault()
    toggleIsOpen()
  }

  return ReactDOM.createPortal(
    <div className={classnames('mobile-modal-view__wrapper', { 'is-visible': isOpen })}>
      <div className="mobile-modal-view__overlay" onClick={handleClose} />
      <div className="mobile-modal-view">
        <div className="py-4 px-3">{children}</div>
      </div>
    </div>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('root-portal')!,
  )
}
