import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getOrderDetails } from '@store/actions/basket-actions'
import { orderDetailsSelector } from '@store/slices/basket-slice'

export const CartThirdStep = (): JSX.Element => {
  const { token } = useParams<{ token: string }>()
  const dispatch = useAppDispatch()

  const orderDetails = useAppSelector(orderDetailsSelector)

  React.useEffect(() => {
    if (token) {
      dispatch(getOrderDetails(token))
    }
  }, [token])

  React.useEffect(() => {
    if (orderDetails && orderDetails.payment_method === 'payu') {
      document.location.href = orderDetails.urls.payment
    }
  }, [orderDetails])

  return (
    <div className="d-flex flex-column align-items-center py-5">
      <p className="text-success fw-bold font-size-34 text-center">Dziękujemy za złożenie zamówienia</p>
      {!!orderDetails && (
        <>
          <p className="text-center">
            Twoje zamówienie o numerze <strong>{orderDetails?.number}</strong> zostało założone. Szczegółowe informacje
            zostały wysłane także na Twój adres e-mail numer zamówienia:-)
          </p>

          {orderDetails.payment_method === 'payu' && (
            <a
              href={orderDetails.urls.payment}
              className="btn fw-semi-bold border-medium py-2 px-5 mt-4 fw-bold text-white bg-warning col-12 col-md-4"
            >
              Zapłać teraz
            </a>
          )}
        </>
      )}
    </div>
  )
}
