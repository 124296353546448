import * as React from 'react'
import { useParams } from 'react-router-dom'
import { PageLayout } from '@components/page-layout'
import { BlogSidebar } from '@modules/blog/blog-sidebar'
import { useGetPageQuery } from '@api/pages'
import { ContentLoader } from '@components/content-loader'
import { TypedQueryResult } from '@api/base'
import { PageDetails } from '@models/pages'

type Params = 'slug'

export const PageView = () => {
  const { slug } = useParams<Params>()

  const { data, isLoading } = useGetPageQuery(slug) as TypedQueryResult<PageDetails>

  return (
    <PageLayout sidebar={<BlogSidebar />}>
      <ContentLoader isLoading={isLoading}>
        <div>
          <p className="text-success fw-bold font-size-22">{data?.title}</p>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.content || '',
            }}
          />
        </div>
      </ContentLoader>
    </PageLayout>
  )
}
