import * as React from 'react'
import { MobileModalView } from '@components/mobile-modal-view'
import { PriceRange } from '@modules/shop/category-details/filters/price-range'
import { CategorySidebarCheckFilter } from '@modules/shop/category-details/filters/category-sidebar-check-filter'
import { useProductFilters } from '@modules/shop/category-details/use-product-filters'

interface Props {
  isOpen: boolean
  toggleIsOpen: () => void
}

export const CategoryFiltersMobileModal = ({ isOpen, toggleIsOpen }: Props): JSX.Element => {
  const filters = useProductFilters()

  return (
    <MobileModalView isOpen={isOpen} toggleIsOpen={toggleIsOpen}>
      <div className="d-flex flex-column">
        <h3 className="text-center text-success mb-5">Ustawienia wyświetlania</h3>
        <div className="flex-grow-1">
          <PriceRange />
          <div className="px-3">
            {filters.map(filter => (
              <CategorySidebarCheckFilter key={filter.name} filter={filter} />
            ))}
          </div>
        </div>
        <div className="mobile-modal-view__submit__wrapper">
          <button className="btn btn-primary mobile-modal-view__submit__button" onClick={toggleIsOpen}>
            <span className="py-2 text-white d-block fw-semi-bold">Gotowe</span>
          </button>
        </div>
      </div>
    </MobileModalView>
  )
}
