import * as React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

interface Props {
  text: string
  destination: string
  className?: string
}

export const HeaderLink = ({ text, destination, className }: Props): JSX.Element => (
  <Link className={classNames('header__link', className)} to={destination}>
    {text}
  </Link>
)
