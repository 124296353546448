import { createAsyncThunk } from '@reduxjs/toolkit'
import { Category, ProductDetails } from '@models/products'
import { commonObjectGet } from '@store/actions/generic-actions'
import { AppDispatch, RootState } from '@store/types'

export const getCategories = createAsyncThunk<Category[], void, { state: RootState; dispatch: AppDispatch }>(
  'products/getCategories',
  async (_, { getState }) => {
    const url = getState().websiteState.appData.urls.products.categories

    return await commonObjectGet<Category[]>(url)
  },
)

export const getProductDetails = createAsyncThunk<ProductDetails, number, { state: RootState }>(
  'products/getProductDetails',
  async (productId, { getState }) => {
    const url = getState().websiteState.appData.urls.products.products

    return await commonObjectGet<ProductDetails>(`${url}${productId}/`)
  },
)
