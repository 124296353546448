import * as React from 'react'
import { Input } from '@components/controls/input'
import { Row } from 'react-bootstrap'

export const CartSecondStepForm = (): JSX.Element => (
  <Row>
    <div className="col-12 col-lg-6">
      <p className="text-success fw-bold font-size-14">Podaj dane do kontaktu i dostawy towaru</p>

      <div className="my-4">
        <Input className="my-3 w-100" name="name" placeholder="Imię i nazwisko" />
        <Input className="my-3 w-100" name="phone" placeholder="Nr telefonu, np. +48 600 555 666" />
        <Input className="my-3 w-100" name="email" placeholder="Adres e-mail" />
        <Input className="my-3 w-100" name="street" placeholder="Ulica" />
        <Input className="my-3 w-100" name="postcode" placeholder="Kod pocztowy" />
        <Input className="my-3 w-100" name="city" placeholder="Miejscowość" />
      </div>
    </div>
  </Row>
)
