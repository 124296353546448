import { configureStore } from '@reduxjs/toolkit'
import authState from '@store/slices/auth-slice'
import websiteState from '@store/slices/website-slice'
import notificationState from '@store/slices/notification-slice'
import productsState from '@store/slices/products-slice'
import basketState from '@store/slices/basket-slice'
import { productsAPI } from '@api/products'
import { pagesAPI } from '@api/pages'

export const store = configureStore({
  reducer: {
    authState,
    websiteState,
    notificationState,
    productsState,
    basketState,
    [pagesAPI.reducerPath]: pagesAPI.reducer,
    [productsAPI.reducerPath]: productsAPI.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(productsAPI.middleware, pagesAPI.middleware),
})

export default store
