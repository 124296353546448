import * as React from 'react'
import { FooterSection } from '@components/footer/footer-section'
import { FooterLink } from '@components/footer/footer-link'
import { SidebarContact } from '@components/sidebar/sidebar-contact'
import { NavigationPath } from '@models/routes'

export const Footer = (): JSX.Element => (
  <div className="bg-white-smoke position-relative d-flex py-5 overflow-hidden">
    <div className="container">
      <img src={require('@assets/images/footer-image.svg')} alt="" className="footer__image" />
      <div className="d-sm-flex d-block justify-content-between mx-5 my-auto col-lg-8 col-12 ms-auto flex-wrap">
        <FooterSection title="FAQ">
          {faqs.map(page => (
            <FooterLink key={page.title} link={page.link} title={page.title} />
          ))}
        </FooterSection>
        <FooterSection title="Strony">
          {pages.map(page => (
            <FooterLink key={page.title} link={page.link} title={page.title} />
          ))}
        </FooterSection>
        <SidebarContact titleClassName="fw-bold" />
      </div>
    </div>
  </div>
)

const pages = [
  { title: 'kontakt', link: NavigationPath.Contact },
  { title: 'koszyk', link: NavigationPath.Cart },
  // { title: 'moje konto', link: '' },
  // { title: 'newsletter', link: '' },
  // { title: 'obserwowane', link: '' },
  { title: 'wysiew nasion i dalsza hodowla', link: '' },
  // { title: 'zamówienie', link: '' },
]

const faqs = [
  { title: 'o firmie', link: '/strona/o-firmie/' },
  { title: 'regulamin sklepu', link: '/strona/regulamin-sklepu/' },
  { title: 'formy płatności', link: '/strona/formy-platnosci/' },
  { title: 'polityka prywatności', link: '/strona/polityka-prywatnosci/' },
  { title: 'termin wysyłki', link: '/strona/termin-wysylki/' },
  { title: 'reklamacje i zwroty', link: '/strona/reklamacje-i-zwroty/' },
]
