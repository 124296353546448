import * as React from 'react'
import classnames from 'classnames'
import { IconWithText } from '@components/icon-with-text'
import { Link, useLocation } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { CategoriesMenu } from '@components/header/categories-menu'
import { useToggle } from '@hooks/use-toggle'
import { useNoScrollableBody } from '@hooks/use-no-scrollable-body'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

export const HamburgerMenu = (): JSX.Element => {
  const [isOpen, toggleIsOpen] = useToggle(false)
  const [isCategoryMenuVisible, toggleIsCategoryMenuVisible] = useToggle(false)

  useNoScrollableBody(isOpen)

  const { pathname } = useLocation()

  useDidUpdateEffect(() => {
    if (isOpen) toggleIsOpen()
    if (isCategoryMenuVisible) toggleIsCategoryMenuVisible()
  }, [pathname])

  return (
    <>
      <button className="hamburger-menu border-medium col-3" onClick={toggleIsOpen}>
        <div className="hamburger-menu__line" />
        <div className="hamburger-menu__line" />
        <div className="hamburger-menu__line" />
      </button>
      <section className={classnames('mobile-menu', { 'is-visible': isOpen })}>
        <i className="uil-times mobile-menu__close" onClick={toggleIsOpen} />
        <img
          src={require('@assets/images/main-logo.svg')}
          alt="Dobrenasiona.pl logo"
          width={200}
          className="mobile-menu__logo"
        />
        <div className="mobile-menu__content">
          <ul>
            <li onClick={toggleIsCategoryMenuVisible} className="mobile-menu__content__link">
              <IconWithText text="Kategorie" icon="uil-store font-20 me-3" />
            </li>
            <li className="mobile-menu__content__link">
              <Link to={NavigationPath.About}>
                <IconWithText text="O nas" icon="uil-eye font-20 me-3" />
              </Link>
            </li>
            <li className="mobile-menu__content__link">
              <Link to={NavigationPath.Blog}>
                <IconWithText text="Blog" icon="uil-comment-alt-lines font-20 me-3" />
              </Link>
            </li>
            <li className="mobile-menu__content__link">
              <Link to={NavigationPath.Contact}>
                <IconWithText text="Kontakt" icon="uil-phone font-20 me-3" />
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <CategoriesMenu isVisible={isCategoryMenuVisible} onHide={toggleIsCategoryMenuVisible} />
    </>
  )
}
