import * as React from 'react'
import classnames from 'classnames'
import { Spinner } from '@components/spinner'

interface Props {
  isLoading: boolean
  children: React.ReactNode | React.ReactNode[] | undefined
  style?: React.CSSProperties
}

export const ContentLoader: React.FC<Props> = ({ style, isLoading, children }) => (
  <div className="position-relative">
    <div className={classnames('content-loader', { 'content-loader--loading': isLoading })} style={style}>
      {children}
    </div>
    <div
      className={classnames('content-loader__spinner', {
        'content-loader__spinner--visible': isLoading,
      })}
    >
      {isLoading && <div role="test-loading" data-testid="test-loading" />}
      <Spinner />
    </div>
  </div>
)
