import * as React from 'react'
import { CategoryFilter } from '@modules/shop/category-details/filters/models'
import { useFormContext } from 'react-hook-form'
import { CategoryFiltersFormInputs } from '@modules/shop/category-details'

interface Props {
  filter: CategoryFilter
}

export const CategorySidebarCheckFilter = ({ filter }: Props): JSX.Element => {
  const { register } = useFormContext<CategoryFiltersFormInputs>()
  return (
    <div className="d-flex flex-column mt-4">
      <span className="text-dark fw-semi-bold d-block mb-2">{filter.name}</span>
      {filter.options.map(option => (
        <div key={option.value}>
          <input
            className="form-check-input border-green-light"
            type="checkbox"
            value={option.value}
            id={`${filter.name}-${option.value}`}
            {...register(filter.key)}
          />
          <label className="form-check-label font-size-13" htmlFor={`${filter.name}-${option.value}`}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  )
}
