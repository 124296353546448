import * as React from 'react'
import { StarShape } from '@components/star-shape'
import { Button } from '@components/button'
import { ProductDetailsOpinion } from '@modules/shop/product-details/product-details-opinion'
import { ProductDetails } from '@models/products'

interface Props {
  productDetails: ProductDetails
}

export const ProductDetailsOpinions = ({ productDetails }: Props): JSX.Element => (
  <div className="my-5">
    <p className="text-success font-size-20 fw-semi-bold">Opinie</p>
    <div className="d-lg-flex d-block align-items-center">
      <div className="d-flex align-items-center">
        <p className="font-size-22 fw-semi-bold mb-0 me-3">4.9/5</p>
        <div>
          {Array.from(Array(5)).map((el, index) => (
            <StarShape size={18} key={index} lastFill={index === 4 ? 60 : 100} />
          ))}
        </div>
      </div>
      <div className="d-flex align-items-center ms-lg-3">
        <span className="fw-semi-bold font-size-13">{productDetails.opinions.length} opinii</span>
        <Button variant="secondary" className="border-small py-2 px-4 ms-3">
          dodaj opinię
        </Button>
      </div>
    </div>
    <div>
      {productDetails.opinions.map(opinion => (
        <ProductDetailsOpinion opinion={opinion} key={opinion.id} />
      ))}
    </div>
  </div>
)
