import * as React from 'react'
import { StarShape } from '@components/star-shape'
import { ProductOpinion } from '@models/products'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'

interface Props {
  opinion: ProductOpinion
}

export const ProductDetailsOpinion = ({ opinion }: Props): JSX.Element => (
  <div className="p-3">
    <div className="d-flex align-items-center mb-3">
      <p className="fw-semi-bold me-2 mb-0"> {opinion.rate}/5</p>
      {Array.from(Array(5)).map((el, index) => (
        <StarShape key={index} />
      ))}
    </div>
    <div className="d-flex">
      <p className="fw-semi-bold me-5">{opinion.name}</p>
      <p className="text-muted">{toDefaultDateTimeFormat(opinion.date)}</p>
    </div>
    <p>{opinion.text}</p>
    <div className="d-flex align-items-center">
      <p className="me-5 text-muted">Czy ta opinia była pomocna?</p>
      <p className="me-5 align-items-center d-flex ">
        <i className="uil-thumbs-up text-warning font-size-22" />
        (1)
      </p>
      <p className="align-items-center d-flex">
        <i className="uil-thumbs-down text-warning font-size-22" />
        (0)
      </p>
    </div>
  </div>
)
