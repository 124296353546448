import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useDebounce } from 'rooks'
import { useFormContext } from 'react-hook-form'

export const PriceRange = (): JSX.Element => {
  const [range, setRange] = React.useState([1, 500])

  const handleMinChange = value => setRange(value)

  const { setValue } = useFormContext()

  const debouncedRange = useDebounce(value => {
    setValue('price_brutto', value)
  }, 300)

  return (
    <div className="px-3 category-details__price-range">
      <span className="text-dark fw-semi-bold my-3 d-block">Cena</span>
      <div className="d-flex justify-content-between px-5 px-lg-0">
        <span className="font-size-12 fw-semi-bold">{formatPrice(range[0])}</span>
        <span className="font-size-12 fw-semi-bold">{formatPrice(range[1])}</span>
      </div>
      <div className="pe-5 px-lg-0 mb-5 mb-lg-0">
        <Slider
          range
          step={5}
          onAfterChange={debouncedRange}
          onChange={handleMinChange}
          value={range}
          allowCross={false}
          min={0}
          max={500}
        />
      </div>
    </div>
  )
}
