import * as React from 'react'
import { Button } from '@components/button'

interface Props {
  avatar: string
  name: string
  text: string
  description: string
}

export const HomeHintSection = ({ avatar, name, text, description }: Props): JSX.Element => (
  <>
    <span className="text-success fw-bold font-size-22">Porada eksperta</span>
    <div className="d-flex pt-3 flex-wrap">
      <div className="home__hint col-lg-3 col-12">
        <div className="d-flex pb-4">
          <img src={require(`@assets/images/${avatar}`)} width={50} className="border-small" />
          <span className="d-block text-white px-3 fw-semi-bold">{name}</span>
        </div>
        <span className="text-white">{text}</span>
      </div>
      <div className="ps-lg-5 pt-5 mt-3 mt-lg-0 col-lg-9 col-12">
        <p>{description}</p>
        <Button className="border-medium py-lg-2 py-3 px-4 mt-lg-4 mt-3">Więcej</Button>
      </div>
    </div>
  </>
)
