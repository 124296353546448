import * as React from 'react'
import { SeedCollectionItem } from '@modules/shop/seed-collection/seed-collection-item'
import { PageLayout } from '@components/page-layout'

export const SeedCollectionView = (): JSX.Element => (
  <PageLayout>
    <div className="d-flex flex-wrap">
      {collection.map(item => (
        <SeedCollectionItem key={item.title} title={item.title} image={item.image} />
      ))}
    </div>
  </PageLayout>
)

const collection = [
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
  { image: 'recommended-item.png', title: 'Werbena Patagońska1' },
]
