import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { useToggle } from '@hooks/use-toggle'

interface Props {
  isOpen: boolean
  handleClose: () => void
  message?: string
  title?: string
}

export const ErrorModal = ({ isOpen, handleClose, message, title }: Props): JSX.Element => {
  const [isShow, handleHide] = useToggle(isOpen)

  return (
    <Modal size="sm" show={isShow} onHide={handleHide} onExited={handleClose} role="error-modal">
      <Modal.Body className="modal-colored-header bg-danger text-center">
        <i className="uil-ban h1 text-white" />
        <h4 className="mt-2 text-white">{title || 'Błąd!'}</h4>
        <p className="mt-3 text-white">{message || 'Wystąpił błąd, prosimy sprobować ponownei później'}</p>
        <button type="button" className="btn btn-light my-2" data-dismiss="modal" onClick={handleHide}>
          OK
        </button>
      </Modal.Body>
    </Modal>
  )
}
