import * as React from 'react'
import { MobileModalView } from '@components/mobile-modal-view'
import { Form, FormGroup } from 'react-bootstrap'
import { SelectOption } from '@components/controls/select'
import { useFormContext } from 'react-hook-form'

interface Props {
  isOpen: boolean
  toggleIsOpen: () => void
  productSortOptions: SelectOption[]
  pageOptions: SelectOption[]
}

export const CategorySettingsMobileModal = ({
  isOpen,
  toggleIsOpen,
  pageOptions,
  productSortOptions,
}: Props): JSX.Element => {
  const { setValue } = useFormContext()

  const handlePerPageChange = event => {
    const option = pageOptions.find(option => option.value === event.target.value)
    if (option) setValue('perPage', option)
  }

  const handleOrderingChange = event => {
    const option = productSortOptions.find(option => option.value === event.target.value)
    if (option) setValue('ordering', option)
  }

  return (
    <MobileModalView isOpen={isOpen} toggleIsOpen={toggleIsOpen}>
      <div className="d-flex flex-column">
        <h3 className="text-center text-success mb-5">Ustawienia wyświetlania</h3>
        <div className="flex-grow-1">
          {!!pageOptions.length && (
            <Form onChange={handlePerPageChange}>
              <p className="mb-3 font-15 fw-semi-bold">Ustawienia strony</p>
              {pageOptions.map(pageOption => (
                <FormGroup key={pageOption.value} className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    value={pageOption.value}
                    name="perPage"
                    id={pageOption.value}
                    defaultChecked={parseInt(pageOption.value, 10) === 10}
                  />
                  <label htmlFor={pageOption.value} className="ms-2 fw-semi-bold font-15">
                    {pageOption.label}
                  </label>
                </FormGroup>
              ))}
              <hr />
            </Form>
          )}

          {!!productSortOptions.length && (
            <Form onChange={handleOrderingChange}>
              <p className="mb-3 font-15 fw-semi-bold">Ustawienia sortowania</p>
              {productSortOptions.map(sortOption => (
                <FormGroup key={sortOption.value} className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    value={sortOption.value}
                    name="ordering"
                    id={sortOption.value}
                    defaultChecked={sortOption.value === '-price_brutto'}
                  />
                  <label htmlFor={sortOption.value} className="ms-2 fw-semi-bold font-15">
                    {sortOption.label}
                  </label>
                </FormGroup>
              ))}
            </Form>
          )}
        </div>
        <div className="mobile-modal-view__submit__wrapper">
          <button className="btn btn-primary mobile-modal-view__submit__button" onClick={toggleIsOpen}>
            <span className="py-2 text-white d-block fw-semi-bold">Gotowe</span>
          </button>
        </div>
      </div>
    </MobileModalView>
  )
}
