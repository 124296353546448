import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { Button } from '@components/button'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { Product } from '@models/products'
import { LayoutView } from '@modules/blog'
import classnames from 'classnames'
import { addBasketItem } from '@store/actions/basket-actions'
import { useNotificationHook } from '@components/../hooks/use-notification-hook'
import { useApiRequest } from '@components/../hooks/use-api-request'
import { useAppDispatch } from '@store/hooks'

interface Props {
  product: Product
  layoutView?: LayoutView
  className?: string
}

export const ProductCard = ({ product, layoutView, className }: Props): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const handleShowDetails = () => {
    navigate(NavigationPath.ProductDetails.replace(':id', product.id.toString()))
  }

  const { isLoading, action: handleAddToCart } = useApiRequest(async (e: React.SyntheticEvent) => {
    e.stopPropagation()
    await dispatch(
      addBasketItem({
        product,
        quantity: 1,
      }),
    )
    addSuccessNotification('Produkt został dodany do koszyka!')
  })

  const isListView = layoutView === 'list'

  return (
    <div
      className={classnames(
        'cursor-pointer',
        layoutView === 'list' ? 'd-flex flex-wrap flex-lg-nowrap col-12 gap-3' : 'col-lg-3 col-md-4 col-6',
        className,
      )}
      onClick={handleShowDetails}
    >
      {product.main_image && (
        <img
          alt={product.name}
          src={product.main_image.xs}
          width={200}
          height={200}
          className="border-medium w-100 mx-auto mx-lg-0"
          style={{ maxHeight: isListView ? '' : '200px', maxWidth: '250px', objectFit: 'cover' }}
        />
      )}
      <div className={classnames({ 'w-100': isListView })}>
        <span className="d-block pt-3 fw-semi-bold font-13 line-clamp" style={{ height: 75 }}>
          {product.name}
        </span>
        {layoutView === 'list' && (
          <span
            className="d-block fw-semi-bold font-size-13"
            dangerouslySetInnerHTML={{ __html: product.short_description }}
          />
        )}
        <div
          className={classnames('d-flex align-items-center mt-3', {
            'justify-content-between': layoutView !== 'list',
            'justify-content-end': layoutView === 'list',
          })}
        >
          <span className="text-success fw-bold p-2">{formatPrice(product.price_brutto)}</span>
          <Button
            disabled={isLoading || !product.is_available}
            className="btn border-medium px-3 py-2 m-2 product-card__order-button"
            onClick={handleAddToCart}
          >
            {isLoading ? 'Dodawanie..' : 'Do koszyka'}
          </Button>
        </div>
      </div>
    </div>
  )
}
