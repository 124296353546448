import * as React from 'react'
import { DefaultSelectStyles, Select, SelectOption } from '@components/controls/select'
import { BlogFiltersMobileModal } from '@modules/blog/blog-filters-mobile-modal'

interface Props {
  categoryOptions: SelectOption[]
  pageOptions: SelectOption[]
}

export const BlogFilters = ({ categoryOptions, pageOptions }: Props): JSX.Element => {
  const [isPageSettingModalVisible, setIsPageSettingModalVisible] = React.useState(false)

  return (
    <>
      <button
        className="btn text-success fw-bold d-inline-block d-lg-none"
        onClick={() => setIsPageSettingModalVisible(true)}
      >
        filtry
      </button>
      <BlogFiltersMobileModal
        pageOptions={pageOptions}
        categoryOptions={categoryOptions}
        isOpen={isPageSettingModalVisible}
        toggleIsOpen={() => setIsPageSettingModalVisible(false)}
      />

      <div className="row d-lg-flex d-none">
        {!!categoryOptions.length && (
          <Select
            inputName="perPage"
            options={categoryOptions}
            className="me-lg-5"
            defaultValue={categoryOptions[0]}
            styles={selectStyles}
          />
        )}
      </div>
    </>
  )
}

const selectStyles = {
  ...DefaultSelectStyles(true),
  container: styles => ({
    ...styles,
    width: '100%',
  }),
}
