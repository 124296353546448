import * as React from 'react'
import { formatPrice } from '@helpers/utils'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { Product } from '@models/products'

interface Props {
  product: Product
}

export const SidebarRecommendedItem = ({ product }: Props): JSX.Element => {
  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.ProductDetails.replace(':id', product.id.toString()))
  }

  return (
    <li className="d-flex my-4 cursor-pointer" onClick={handleShowDetails}>
      {product.main_image && <img src={require(`@assets/images/${product.main_image?.xs}`)} />}
      <div className="ms-3 d-flex flex-column">
        <span className="d-block">{product.name}</span>
        <span className="d-block">{product.short_description}</span>
        <span className="text-success fw-bold mt-auto">{formatPrice(product.price_brutto)}</span>
      </div>
    </li>
  )
}
