import * as React from 'react'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import { CarouselNextButton } from '@components/carousel/carousel-next-button'
import { CarouselPreviousButton } from '@components/carousel/carousel-previous-button'
import classnames from 'classnames'

export const Paginator = ({ ...props }: ReactPaginateProps) => (
  <ReactPaginate
    {...props}
    className={classnames('paginator', props.className)}
    activeClassName="paginator__page--active"
    pageClassName="paginator__page"
    nextLabel={<CarouselNextButton />}
    breakLabel=""
    previousLabel={<CarouselPreviousButton />}
    marginPagesDisplayed={0}
    pageRangeDisplayed={3}
  />
)
