import * as React from 'react'
import classnames from 'classnames'

export const CarouselPreviousButton = ({ ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button
    {...props}
    className={classnames('border-small-mirror bg-light-gray border-green py-1 px-2', props.className)}
  >
    <i className="uil-angle-left-b text-warning font-size-20" />
  </button>
)
