import * as React from 'react'
import * as R from 'ramda'
import { BlogCard } from '@modules/blog/blog-card'

interface Props {
  articles: any
}

export const HomeBlogSection = ({ articles }: Props): JSX.Element => {
  const blogArticles = R.take(3, articles)

  return (
    <div>
      <p className="text-success fw-bold font-size-22">Blog</p>
      <div className="row">
        {blogArticles.map(article => (
          <BlogCard key={article.title} article={article} />
        ))}
      </div>
    </div>
  )
}
