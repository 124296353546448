import * as React from 'react'
import classNames from 'classnames'

interface Props {
  title: string
  message: string
  icon: string
  className?: string
}

export const HomeStatement = ({ title, message, icon, className }: Props): JSX.Element => (
  <div className={classNames('d-flex home__statement__card border-large', className)}>
    <img src={require(`@assets/images/icons/${icon}`)} className="p-3" />
    <div className="d-flex flex-column justify-content-center">
      <span className="d-flex text-warning fw-bold">{title}</span>
      <span className="font-size-12">{message}</span>
    </div>
  </div>
)
