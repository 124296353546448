import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNotificationHook } from '@components/../hooks/use-notification-hook'
import { ErrorModal } from '@components/modals/error-modal'
import { SuccessModal } from '@components/modals/success-modal'

export enum Variant {
  Success,
  Error,
}

export interface NotificationMessage {
  message: string
  title: string
  variant: Variant
  id: number
}

export const NotificationHandler = () => {
  const { messages, removeMessage } = useNotificationHook()

  return (
    <>
      <ToastContainer icon={false} autoClose={3000} limit={5} />
      {messages.map(message =>
        message.variant === Variant.Error ? (
          <ErrorModal
            isOpen={true}
            key={message.id}
            title={message.title}
            message={message.message}
            handleClose={() => removeMessage(message)}
          />
        ) : (
          <SuccessModal
            isOpen={true}
            key={message.id}
            title={message.title}
            message={message.message}
            handleClose={() => removeMessage(message)}
          />
        ),
      )}
    </>
  )
}
