import * as React from 'react'
import classnames from 'classnames'
import { ProductDetails } from '@models/products'

interface Props {
  product: ProductDetails
}

export const ProductDetailsDescription = ({ product }: Props): JSX.Element => {
  const [activeSection, setActiveSection] = React.useState('description')
  const sectionKinds = [{ name: 'Opis', type: 'description' }]
  if (product.additional_details) {
    sectionKinds.push({ name: 'Dodatkowe informacje', type: 'details' })
  }

  const isActive = section => activeSection === section

  return (
    <div className="col-lg-6 col-12 me-5">
      <div className="d-flex mb-4">
        {sectionKinds.map(section => (
          <div
            onClick={() => setActiveSection(section.type)}
            key={section.type}
            className={classnames('text-success font-size-20 fw-semi-bold px-4 py-2 cursor-pointer', {
              'border-success border-medium border': isActive(section.type),
            })}
          >
            {section.name}
          </div>
        ))}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: isActive('description') ? product.description : product.additional_details }}
      />
    </div>
  )
}
