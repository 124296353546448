import * as React from 'react'
import { SidebarRecommendedItem } from '@components/sidebar/sidebar-recommended-item'
import { Product } from '@models/products'

export const SidebarRecommendedList = (): JSX.Element => (
  <>
    <span className="text-success fw-bold font-size-22 my-3 d-block">Polecane Produkty</span>
    <ul className="ps-0">
      {fakeProducts.map(product => (
        <SidebarRecommendedItem key={product.id} product={product} />
      ))}
    </ul>
  </>
)

export const fakeProducts: Product[] = []
