import * as React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { PageLayout } from '@components/page-layout'
import { ProductCard } from '@components/product-card'
import { ItemLayout } from '@components/item-layout'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CategorySidebar } from '@modules/shop/category-details/category-sidebar'
import { CategoryDetails, Product } from '@models/products'
import { commonObjectGet } from '@store/actions/generic-actions'
import { getCategoryUrl } from '@components/sidebar/sidebar-categories-row'
import { SelectOption } from '@components/controls/select'
import { ContentLoader } from '@components/content-loader'
import { useApiRequest } from '@hooks/use-api-request'
import { CategoryFilters } from '@modules/shop/category-details/filters/category-filters'
import { IsfSidebar } from '@modules/shop/common/isf-sidebar'
import { RootState } from '@store/types'
import { TypedQueryResult } from '@api/base'
import { useGetProductQuery } from '@api/products'

export const productSortOptions: SelectOption[] = [
  { label: 'Cena malejąco', value: 'price_brutto' },
  { label: 'Cena rosnąco', value: '-price_brutto' },
]

export const pageOptions: SelectOption[] = [
  { label: '16 na stronie', value: '16' },
  { label: '32 na stronie', value: '32' },
]

type Params = 'id' | 'slug'

export interface CategoryFiltersFormInputs {
  ordering: SelectOption
  perPage: SelectOption
  price_brutto: [number, number] | undefined
  producers: string[]
  destinations: string[]
}
const emptyList = []
export const CategoryDetailsView = (): JSX.Element => {
  const { id: categoryId = '' } = useParams<Params>()
  const [page, setPage] = React.useState(1)

  const [categoryDetails, setCategoryDetails] = React.useState<CategoryDetails>()
  const url = useSelector((state: RootState) => state.websiteState.appData.urls.products.categories)

  const { isLoading, action: fetchAll } = useApiRequest(async () =>
    setCategoryDetails(await commonObjectGet<CategoryDetails>(`${url}${categoryId}/`)),
  )

  React.useEffect(() => {
    if (categoryId) {
      fetchAll()
      setPage(1)
    }
  }, [categoryId])

  const methods = useForm<CategoryFiltersFormInputs>({
    defaultValues: {
      ordering: productSortOptions[0],
      perPage: pageOptions[0],
      price_brutto: undefined,
      producers: [],
      destinations: [],
    },
  })

  const watchedFilter = useWatch({
    control: methods.control,
  })

  const onPageChange = (item: { selected: number }) => setPage(item.selected + 1)

  const { data: products = emptyList, isLoading: isProductLoading } = useGetProductQuery({
    category_id: categoryId,
    page,
    page_size: watchedFilter.perPage?.value || '16',
    ordering: watchedFilter.ordering?.value || 'price_brutto',
    price_brutto: watchedFilter.price_brutto,
    destinations: watchedFilter.destinations,
    producers: watchedFilter.producers,
  }) as TypedQueryResult<Product[]>

  return (
    <PageLayout
      sidebar={
        <FormProvider {...methods}>
          <CategorySidebar />
        </FormProvider>
      }
    >
      <IsfSidebar />

      <div>
        {categoryDetails && (
          <>
            <p className="text-success fw-bold font-size-22">{categoryDetails.name}</p>
            <div dangerouslySetInnerHTML={{ __html: categoryDetails.description }} />
            <div className="d-flex flex-wrap mb-4">
              {categoryDetails.children.map(category => (
                <Link
                  key={category.name}
                  to={getCategoryUrl(category)}
                  className="btn fw-semi-bold me-4 border-small font-size-13 fw-bold p-3 mb-4 mb-lg-3 text-warning bg-orange-light"
                >
                  {category.name}
                </Link>
              ))}
            </div>
          </>
        )}
        <ContentLoader isLoading={isLoading || isProductLoading}>
          {!products.length ? (
            <p className="text-center">Kategoria jest pusta ;-(</p>
          ) : (
            <FormProvider {...methods}>
              <ItemLayout
                perPage={watchedFilter.perPage?.value || '16'}
                filters={<CategoryFilters productSortOptions={productSortOptions} pageOptions={pageOptions} />}
                onPageChange={onPageChange}
              >
                {layoutView => (
                  <div className="row my-4 gy-4">
                    {products.map(product => (
                      <ProductCard layoutView={layoutView} key={product.id} product={product} />
                    ))}
                  </div>
                )}
              </ItemLayout>
            </FormProvider>
          )}
        </ContentLoader>
      </div>
    </PageLayout>
  )
}
