import * as React from 'react'
import classnames from 'classnames'
import classNames from 'classnames'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string | JSX.Element
  icon: string
  className?: string
  iconClassName?: string
  textClassName?: string
  width?: number
}

export const IconWithText = ({
  text,
  textClassName,
  iconClassName,
  className,
  width,
  icon,
  ...props
}: Props): JSX.Element => (
  <div className={classnames('icon-with-text d-flex align-items-center', className)} {...props}>
    <i className={classNames(icon, iconClassName)} />
    <span className={textClassName}>{text}</span>
  </div>
)
