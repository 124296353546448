import * as React from 'react'
import { DefaultSelectStyles, Select, SelectOption } from '@components/controls/select'
import { CategorySettingsMobileModal } from '@modules/shop/category-details/filters/category-settings-mobile-modal'
import { CategoryFiltersMobileModal } from '@modules/shop/category-details/filters/category-filters-mobile-modal'

interface Props {
  productSortOptions: SelectOption[]
  pageOptions: SelectOption[]
}

export const CategoryFilters = ({ productSortOptions, pageOptions }: Props): JSX.Element => {
  const [isPageSettingModalVisible, setIsPageSettingModalVisible] = React.useState(false)
  const [isCategoryFiltersModalVisible, seIsCategoryFiltersModalVisible] = React.useState(false)

  return (
    <>
      <button
        className="btn text-success fw-bold d-inline-block d-lg-none"
        onClick={() => seIsCategoryFiltersModalVisible(true)}
      >
        filtry
      </button>
      <CategoryFiltersMobileModal
        isOpen={isCategoryFiltersModalVisible}
        toggleIsOpen={() => seIsCategoryFiltersModalVisible(false)}
      />
      <button
        className="btn text-success fw-bold d-inline-block d-lg-none"
        onClick={() => setIsPageSettingModalVisible(true)}
      >
        ustawienia
      </button>
      <CategorySettingsMobileModal
        pageOptions={pageOptions}
        productSortOptions={productSortOptions}
        isOpen={isPageSettingModalVisible}
        toggleIsOpen={() => setIsPageSettingModalVisible(false)}
      />
      <div className="row d-lg-flex d-none">
        {!!pageOptions.length && (
          <div className="col-12 col-lg-6">
            <Select
              inputName="perPage"
              options={pageOptions}
              className="me-lg-5"
              defaultValue={pageOptions[0]}
              styles={selectStyles}
            />
          </div>
        )}

        {!!productSortOptions.length && (
          <div className="col-6">
            <Select
              inputName="ordering"
              options={productSortOptions}
              defaultValue={productSortOptions[0]}
              styles={selectStyles}
            />
          </div>
        )}
      </div>
    </>
  )
}

const selectStyles = {
  ...DefaultSelectStyles(true),
  container: styles => ({
    ...styles,
    width: '100%',
  }),
}
