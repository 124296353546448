import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrderDetails } from '@models/order'
import {
  addBasketItem,
  getBasketDetails,
  getOrderDetails,
  removeBasketItem,
  updateBasketItem,
} from '@store/actions/basket-actions'

import { RootState } from '@store/types'

export interface BasketState {
  basketOrder: OrderDetails
  orderDetails: undefined | OrderDetails
}

const initialState: BasketState = {
  orderDetails: undefined,
  basketOrder: {
    city: '',
    email: '',
    number: null,
    invoice: false,
    urls: {
      payment: '',
      details: '',
    },
    payment_status: 'cash_on_delivery',
    payment_method: 'cash_on_delivery',
    invoice_city: '',
    invoice_name: '',
    invoice_phone: '',
    invoice_postcode: '',
    name: '',
    invoice_street: '',
    invoice_tax_number: '',
    phone: '',
    postcode: '',
    street: '',
    total_price_brutto: '0',
    transport_price_brutto: '0',
    items: [],
    token: '',
    id: 0,
    price_brutto: '0',
  },
}

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    updateBasketDetails(state, action: PayloadAction<OrderDetails>) {
      state.basketOrder = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getBasketDetails.fulfilled, (state, action) => {
      state.basketOrder = action.payload
    })
    builder.addCase(addBasketItem.fulfilled, (state, action) => {
      state.basketOrder = action.payload
    })
    builder.addCase(removeBasketItem.fulfilled, (state, action) => {
      state.basketOrder = action.payload
    })
    builder.addCase(updateBasketItem.fulfilled, (state, action) => {
      state.basketOrder = action.payload
    })
    builder.addCase(getOrderDetails.fulfilled, (state, action) => {
      state.orderDetails = action.payload
    })
  },
})

export const { updateBasketDetails } = basketSlice.actions

export const orderDetailsSelector = (state: RootState) => state.basketState.orderDetails
export const basketDetailsSelector = (state: RootState) => state.basketState.basketOrder

export default basketSlice.reducer
