import * as React from 'react'
import { MobileModalView } from '@components/mobile-modal-view'
import { Form, FormGroup } from 'react-bootstrap'
import { SelectOption } from '@components/controls/select'
import { useFormContext } from 'react-hook-form'

interface Props {
  isOpen: boolean
  toggleIsOpen: () => void
  categoryOptions: SelectOption[]
  pageOptions: SelectOption[]
}

export const BlogFiltersMobileModal = ({ isOpen, toggleIsOpen, pageOptions, categoryOptions }: Props): JSX.Element => {
  const { setValue } = useFormContext()

  const handlePerPageChange = event => {
    const option = pageOptions.find(option => option.value === event.target.value)
    if (option) setValue('perPage', option)
  }

  const handleCategoryChange = event => {
    const option = categoryOptions.find(category => category.value === event.target.value)
    console.log(option)
  }

  return (
    <MobileModalView isOpen={isOpen} toggleIsOpen={toggleIsOpen}>
      <div className="d-flex flex-column">
        <h3 className="text-center text-success mb-5">Filtry</h3>
        <div className="flex-grow-1">
          {!!pageOptions.length && (
            <Form onChange={handlePerPageChange}>
              <p className="mb-3 font-15 fw-semi-bold">Ustawienia listy</p>
              {pageOptions.map(el => (
                <FormGroup key={el.value} className="d-flex align-items-center">
                  <Form.Check type="radio" value={el.value} name="perPage" id={el.value} />
                  <label htmlFor={el.value} className="ms-2 fw-semi-bold font-15">
                    {el.label}
                  </label>
                </FormGroup>
              ))}
              <hr />
            </Form>
          )}

          {!!categoryOptions.length && (
            <Form onChange={handleCategoryChange}>
              <p className="mb-3 font-15 fw-semi-bold">Kategorie</p>
              {categoryOptions.map(el => (
                <FormGroup key={el.value} className="d-flex align-items-center mb-1">
                  <Form.Check type="checkbox" value={el.value} id={el.value} />
                  <label htmlFor={el.value} className="ms-2 fw-semi-bold font-15">
                    {el.label}
                  </label>
                </FormGroup>
              ))}
            </Form>
          )}
        </div>
        <div className="mobile-modal-view__submit__wrapper">
          <button className="btn btn-primary mobile-modal-view__submit__button" onClick={toggleIsOpen}>
            <span className="py-2 text-white d-block fw-semi-bold">Gotowe</span>
          </button>
        </div>
      </div>
    </MobileModalView>
  )
}
