import * as React from 'react'
import { useSelector } from 'react-redux'
import { getCategories } from '@store/actions/products-actions'
import { SidebarCategoriesRow } from '@components/sidebar/sidebar-categories-row'
import { categoriesSelector } from '@store/slices/products-slice'
import { useAppDispatch } from '@store/hooks'

export const SidebarCategories = () => {
  const categories = useSelector(categoriesSelector).filter(row => !row.parent_id)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(getCategories())
  }, [])

  return (
    <div className="bg-light-gray py-3 ms-3 pe-3 border-medium">
      <div className="border-medium bg-success text-center text-white fw-bold py-3 ms-n3">KATEGORIE PRODUKTÓW</div>
      <ul className="pt-4">
        {categories.map(category => (
          <SidebarCategoriesRow key={category.id} category={category} />
        ))}
      </ul>
    </div>
  )
}
