import * as React from 'react'
import { BlogCard } from '@modules/blog/blog-card'
import { blogArticles } from '@modules/home'
import { PageLayout } from '@components/page-layout'
import { BlogSidebar } from '@modules/blog/blog-sidebar'
import * as R from 'ramda'
import { SelectOption } from '@components/controls/select'
import { FormProvider, useForm } from 'react-hook-form'
import { ItemLayout } from '@components/item-layout'
import { BlogFilters } from '@modules/blog/blog-filters'

export type LayoutView = 'grid' | 'list'

interface FormInputs {
  page: SelectOption
  category: SelectOption
}

export const BlogView = (): JSX.Element => {
  const articles = R.take(3, blogArticles)

  const methods = useForm<FormInputs>()

  const categoryOptions = [
    { label: 'Kategoria 1', value: '1' },
    { label: 'Kategoria 2', value: '2' },
  ]

  const onPageChange = (item: { selected: number }) => console.log(item.selected + 1)

  return (
    <PageLayout sidebar={<BlogSidebar />}>
      <div>
        <p className="text-success fw-bold font-size-22">Blog</p>
        <FormProvider {...methods}>
          <ItemLayout
            onPageChange={onPageChange}
            filters={<BlogFilters pageOptions={[]} categoryOptions={categoryOptions} />}
          >
            {layoutView => (
              <div className="row">
                {articles.map(article => (
                  <BlogCard layoutView={layoutView} key={article.title} article={article} />
                ))}
              </div>
            )}
          </ItemLayout>
        </FormProvider>
      </div>
    </PageLayout>
  )
}
