import * as React from 'react'
import { PageLayout } from '@components/page-layout'
import { NavigationPath } from '@models/routes'
import { Link, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getOrderDetails } from '@store/actions/basket-actions'
import { orderDetailsSelector } from '@store/slices/basket-slice'

export const OrderPaymentCompleteView = (): JSX.Element => {
  const { token } = useParams<{ token: string }>()
  const dispatch = useAppDispatch()
  const orderDetails = useAppSelector(orderDetailsSelector)

  React.useEffect(() => {
    if (token) {
      dispatch(getOrderDetails(token))
    }
  }, [token])

  return (
    <PageLayout showHeader={false} showSidebar={false}>
      <div className="w-100">
        <div className="d-flex mt-5 align-items-center">
          <Link to={NavigationPath.Home}>
            <img src={require('@assets/images/main-logo.svg')} alt="Dobrenasiona.pl logo" width={200} />
          </Link>
        </div>
        <div className="col-sm-10 col-12 mx-auto">
          {!!orderDetails && (
            <div className="d-flex flex-column align-items-center py-5">
              <p className="text-success fw-bold font-size-34">
                Dziękujemy za opłacenie zamówienia numer {orderDetails.number}
              </p>
              {orderDetails.payment_status === 'paid' ? (
                <p>Dziękujemy Twoje zamówienie zostało opłacone.</p>
              ) : (
                <p>Dziękujemy za opłacenie zamówienia, gdy tylko zaksięgujemy wpłatę w PayU wyślemy Twoje zamówienie</p>
              )}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  )
}
