import { AxiosError } from 'axios'
import { useNotificationHook } from '@components/../hooks/use-notification-hook'

interface HandleConfiguration {
  excludeFields?: string[]
  nonFieldErrorsAs?: string
  showGlobalError?: boolean
}

export const useHandleAxiosFormErrors = () => {
  const { addErrorMessage } = useNotificationHook()
  const initialConfiguration: HandleConfiguration = { excludeFields: [], showGlobalError: true }

  return (error: AxiosError, setErrors: any, configuration: HandleConfiguration = initialConfiguration) => {
    if (!configuration.excludeFields) {
      configuration.excludeFields = []
    }
    if (configuration.nonFieldErrorsAs) {
      configuration.excludeFields.push('non_field_errors')
    }
    if (error.response?.status === 400) {
      if (error.response.data.non_field_errors) {
        setErrors(configuration.nonFieldErrorsAs, {
          type: 'manual',
          message: error.response.data.non_field_errors.join('. '),
        })
      }

      Object.entries<string[]>(error.response.data).map(([key, message]) => {
        if (!configuration.excludeFields?.includes(key)) {
          if (typeof message[0] === 'object') {
            handleAxiosFormErrorsNestedObject(key, message, setErrors)
          } else {
            setErrors(key, {
              type: 'manual',
              message: (message || []).join('. '),
            })
          }
        }
      })
    } else {
      if (configuration.showGlobalError) {
        addErrorMessage()
      }
      throw error
    }
  }
}

const handleAxiosFormErrorsNestedObject = (fieldName, message, setErrors) => {
  message.map((validation, index) => {
    Object.keys(validation).map(key => {
      const newKey = `${fieldName}.${index}.${key}`
      setErrors(newKey, {
        type: 'server',
        message: validation[key].join('. '),
      })
    })
  })
}

export const mapFormError = (error: AxiosError, old_field, new_field) => {
  if (error.response?.status === 400) {
    if (error.response.data[old_field] && !error.response.data[new_field]) {
      error.response.data[new_field] = error.response.data[old_field]
    }
  }
  return error
}

export const useHandleAxiosServerErrors = () => {
  const { addErrorMessage } = useNotificationHook()

  return (error: AxiosError) => {
    if (error.response?.status === 400) {
      const keys = Object.keys(error.response.data)
      addErrorMessage('Błąd', error.response.data[keys[0]][0])
    } else {
      addErrorMessage()
    }
  }
}

interface ParsedErrorData {
  type: string
  message: string
}

export const setErrorForDynamicKey = (dynamicKey: string, setError: any) => (key: string, data: ParsedErrorData) => {
  setError(dynamicKey, data)
}
