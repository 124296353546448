import axios, { CancelTokenSource } from 'axios'

export async function commonObjectGet<T>(url: string, params?: any, cancelTokenSource?: CancelTokenSource): Promise<T> {
  const cancelOpts = { cancelToken: cancelTokenSource?.token }
  const { data } = await axios.get(url, {
    params,
    ...cancelOpts,
  })
  return data
}

export async function commonObjectDelete<T>(
  url: string,
  reason?,
  payload?,
  cancelTokenSource?: CancelTokenSource,
): Promise<T> {
  const cancelOpts = { cancelToken: cancelTokenSource?.token }
  const { data } = await axios.delete(url, {
    data: {
      reason,
      ...payload,
    },
    ...cancelOpts,
  })
  return data
}

export async function commonObjectPost<T>(url: string, payload?, cancelTokenSource?: CancelTokenSource): Promise<T> {
  const cancelOpts = { cancelToken: cancelTokenSource?.token }
  const { data } = await axios.post(url, payload, cancelOpts)
  return data
}

export async function commonObjectPut<T>(url: string, payload?, cancelTokenSource?: CancelTokenSource): Promise<T> {
  const cancelOpts = { cancelToken: cancelTokenSource?.token }
  const { data } = await axios.put(url, payload, cancelOpts)
  return data
}

export async function commonObjectPatch<T>(url: string, payload?, cancelTokenSource?: CancelTokenSource): Promise<T> {
  const cancelOpts = { cancelToken: cancelTokenSource?.token }
  const { data } = await axios.patch(url, payload, cancelOpts)
  return data
}

export function getCancelSource(): CancelTokenSource {
  return axios.CancelToken.source()
}
