import { PriceRange } from '@modules/shop/category-details/filters/price-range'
import * as React from 'react'
import { CategoryFilter } from '@modules/shop/category-details/filters/models'
import { CategorySidebarCheckFilter } from '@modules/shop/category-details/filters/category-sidebar-check-filter'

interface Props {
  filters: CategoryFilter[]
}

export const CategorySidebarFilters = ({ filters }: Props): JSX.Element => (
  <div className="mt-4">
    <strong className="font-size-20 text-success px-3">Filtry</strong>
    <PriceRange />
    <div className="px-3">
      {filters.map(filter => (
        <CategorySidebarCheckFilter key={filter.name} filter={filter} />
      ))}
    </div>
  </div>
)
