import axios, { AxiosRequestConfig, AxiosRequestTransformer } from 'axios'
import { BaseQueryApi } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import {
  LazyQueryTrigger,
  TypedUseQueryStateResult,
  UseLazyQueryLastPromiseInfo,
} from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { formatDate } from '@helpers/date-helper'
import * as queryString from 'querystring'
import { setStandardPaginator } from '@store/slices/website-slice'
import { AppDispatch, RootState } from '@store/types'

export const multiPartHeaders = {
  'Content-Type': 'multipart/form-data',
}

export type TypedQueryResult<T> = TypedUseQueryStateResult<T, unknown, any> & { refetch: () => void }
export type TypedLazyQueryResult<T> = [
  LazyQueryTrigger<any>,
  TypedUseQueryStateResult<T, unknown, any> & { refetch: () => void },
  UseLazyQueryLastPromiseInfo<any>,
]

const getTransformedPayload = params => {
  const payload = {}
  Object.entries(params).map(([key, value]) => {
    if (value instanceof Date) {
      payload[key] = formatDate(value, 'yyyy-MM-dd HH:mm')
    } else {
      payload[key] = value
    }
  })
  return payload
}

const apiInstance = axios.create({
  baseURL: '',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  responseType: 'json',
  paramsSerializer: params => queryString.stringify(getTransformedPayload(params)),

  transformRequest: [
    params => {
      if (!params) return params
      return getTransformedPayload(params)
    },
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

export interface BaseQueryParams {
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  responseTransformer?: (result) => any
}

export const axiosBaseQuery = async <ResultType = unknown>(
  { url, method, data, params, responseTransformer }: BaseQueryParams,
  dispatch: AppDispatch,
): Promise<QueryReturnValue<ResultType, FetchBaseQueryError>> => {
  try {
    const result = await apiInstance({ url, method, data, params })
    const response = result.data

    if (
      response &&
      typeof response.count !== 'undefined' &&
      typeof response.results !== 'undefined' &&
      !data?.skip_paginator
    ) {
      dispatch(setStandardPaginator(response))
    }

    return { ...result, data: (responseTransformer ? responseTransformer(response) : response) as ResultType }
  } catch (error) {
    return { error }
  }
}

export const apiQueryFunction =
  <ResultType, Params>(action: (state: RootState, data: Params) => BaseQueryParams) =>
  async (data: Params, { getState, dispatch }: BaseQueryApi) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (await axiosBaseQuery<ResultType>(action(getState(), data), dispatch)) as QueryReturnValue<
      ResultType,
      FetchBaseQueryError
    >
