import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CartSecondStepFormInputs } from '@modules/shop/cart/cart-second-step/index'
import { InpostGeowidgetReact } from 'inpost-geowidget-react'

interface ParcelLock {
  name: string
  address: {
    line1: string
    line2: string
  }
}

export const CartSecondStepDeliveryMethodInpost = (): JSX.Element => {
  const { setValue, control } = useFormContext<CartSecondStepFormInputs>()
  const parcelBox = React.useRef<null | HTMLDivElement>(null)

  const selectedPoint = useWatch({ control, name: 'transport_option_context' }) as any as ParcelLock

  const apiReady = React.useCallback(() => {
    // You can also use API Methods, as example
    // console.log(api);
  }, [])

  const pointSelect = React.useCallback(
    (point: any) => {
      setValue('transport_option_context', point)
      console.log('Object of selected point: ', point)
      parcelBox.current?.scrollIntoView({ behavior: 'smooth' })
    },
    [setValue],
  )

  return (
    <>
      <div style={{ height: 1000, width: 1000 }} className="mb-4">
        <InpostGeowidgetReact
          apiReady={apiReady}
          identifier="Geo1"
          token={process.env.SHOP_FRONTEND_INPOST_KEY}
          pointSelect={pointSelect}
        />
      </div>
      {selectedPoint && (
        <div className="mb-3">
          Wybrany punkt: <strong>{selectedPoint.name}</strong> ({selectedPoint.address.line1},{' '}
          {selectedPoint.address.line2})
        </div>
      )}
      <div ref={parcelBox} />
    </>
  )
}
