import * as React from 'react'
import { Sidebar } from '@components/sidebar/sidebar'
import { Header } from '@components/header/header'
import classnames from 'classnames'
import { Footer } from '@components/footer/footer'

interface Props {
  children: React.ReactNode
  sidebar?: React.ReactElement
  showSidebar?: boolean
  showHeader?: boolean
}

export const PageLayout = ({
  children,
  showHeader = true,
  showSidebar = true,
  sidebar = <Sidebar />,
}: Props): JSX.Element => (
  <>
    {showHeader && <Header />}
    <div className="d-flex container gap-lg-5 gap-0">
      {showSidebar && <div className="col-3 d-lg-block d-none">{sidebar}</div>}
      <div className={classnames('col-12', showSidebar ? 'col-lg-9' : 'col-lg-12')}>{children}</div>
    </div>
    <Footer />
  </>
)
