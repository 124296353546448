import * as React from 'react'
import { NavigationPath } from '@models/routes'

export const SearchBox = (): JSX.Element => (
  <form method="get" action={NavigationPath.Search} className="search border-medium border-green-light col-lg-2 d-flex">
    <input type="search" className="search__input " name="q" placeholder="szukaj..." />
    <button className="btn shadow-none" type="submit">
      <i className="uil-search text-warning font-size-20" />
    </button>
  </form>
)
