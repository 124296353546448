import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { LayoutView } from '@modules/blog/index'
import classnames from 'classnames'

interface Props {
  article: any
  layoutView?: LayoutView
}

export const BlogCard = ({ article, layoutView = 'grid' }: Props): JSX.Element => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(NavigationPath.BlogArticle.replace(':id', article.title))
  }

  return (
    <div
      className={classnames(
        'p-3 cursor-pointer col-12 text-center',
        layoutView === 'list' ? 'col-lg-12 d-flex gap-3' : 'col-sm-4',
      )}
      onClick={handleClick}
    >
      <img
        src={require(`@assets/images/${article.image}`)}
        className="blog__card__image border-medium"
        alt="dobre-nasiona blog"
      />
      <div>
        <p className="fw-bold py-3">{article.title}</p>
        <p>{article.text}</p>
      </div>
    </div>
  )
}
