import * as React from 'react'
import { Alert } from 'react-bootstrap'

export const IsfSidebar: React.FC = () => {
  const [show, setShow] = React.useState(true)

  if (!show) {
    return null
  }

  return (
    <Alert
      variant="dark"
      onClose={() => setShow(false)}
      dismissible
      className="bg-light-gray row border-small border-0 align-items-center mx-1 mx-md-0"
    >
      <div className="d-flex col-md-4 col-12 align-items-center">
        <img src={require('@assets/images/icons/piorin.svg')} alt="" />
        <p className="mx-2 fw-semi-bold mb-0 ms-2">Państwowa inspekcja Ochrony Roślin i Nasiewnictwa</p>
      </div>

      <p className="col-md-8 col-12 mb-0">
        Jesteśmy podmiotem zarejestrowanym w bazie PIORiN pod nr: PL-24/66/7059. Posiadamy stosowne uprawnienia do
        wystawiania EU paszportów roślinnych i kontrolowania jakości materiału roślinnego.{' '}
        <a className="text-warning d-block d-sm-inline-block" href="http://piorin.gov.pl/" target="_blank">
          Dowiedz się więcej
        </a>
      </p>
    </Alert>
  )
}
