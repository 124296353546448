import * as React from 'react'
import { SidebarContact } from '@components/sidebar/sidebar-contact'
import { SidebarRecommendedList } from '@components/sidebar/sidebar-recommended-list'
import { SidebarCategories } from '@components/sidebar/sidebar-categories'

export const Sidebar = (): JSX.Element => (
  <>
    <SidebarCategories />
    <SidebarRecommendedList />
    <div className="sidebar__contact-card my-5">
      <SidebarContact />
    </div>
    <img className="mb-4" src={require('@assets/images/logo-isf.svg')} alt="" />
  </>
)
