import * as React from 'react'
import { PageLayout } from '@components/page-layout'
import { CartFirstStep } from '@modules/shop/cart/cart-first-step'
import { CartSecondStep } from '@modules/shop/cart/cart-second-step'
import { CartThirdStep } from '@modules/shop/cart/cart-third-step'
import { CartStepProgress } from '@modules/shop/cart/cart-step-progress'
import { NavigationPath } from '@models/routes'
import { Link, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getBasketDetails } from '@store/actions/basket-actions'
import { basketDetailsSelector } from '@store/slices/basket-slice'

export const CartView = (): JSX.Element => {
  const { step } = useParams<{ step: string }>()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(getBasketDetails())
  }, [])

  const basketDetails = useAppSelector(basketDetailsSelector)

  return (
    <PageLayout showHeader={false} showSidebar={false}>
      <div className="w-100">
        <div className="d-lg-flex d-block mt-5 align-items-center justify-content-center">
          <Link to={NavigationPath.Home} className="mx-auto d-flex justify-content-center mb-5 mb-lg-0">
            <img src={require('@assets/images/main-logo.svg')} alt="Dobrenasiona.pl logo" width={200} />
          </Link>
          <CartStepProgress step={step || 'summary'} />
        </div>
        <div className="col-md-10 col-12 mx-auto">
          {basketDetails?.token && (
            <>
              {(step === 'summary' || !step) && <CartFirstStep basketDetails={basketDetails} />}
              {step === 'params' && <CartSecondStep basketDetails={basketDetails} />}
            </>
          )}
          {step === 'confirmed' && <CartThirdStep />}
        </div>
      </div>
    </PageLayout>
  )
}
