import * as React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  link: string
  title: string
}
export const FooterLink = ({ link, title }: Props): JSX.Element => (
  <Link to={link} className="d-block mb-1">
    {title}
  </Link>
)
