import * as React from 'react'
import { CartPurchasesTable } from '@modules/shop/cart/cart-purchases-table'
import { formatPrice } from '@helpers/utils'
import { Button } from '@components/button'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { OrderDetails } from '@models/order'
import { IconWithText } from '@components/icon-with-text'
import { ReturnButton } from '@components/return-button'

interface Props {
  basketDetails: OrderDetails
}

export const CartFirstStep = ({ basketDetails }: Props): JSX.Element => {
  const isNextDisabled = !basketDetails?.items?.length

  const navigate = useNavigate()

  const nextStep = () => navigate(NavigationPath.CartWithParams.replace(':step', 'params'))

  return (
    <div className="p-1 py-5">
      <p className="text-success fw-bold font-size-16 ms-5">Koszyk</p>

      <CartPurchasesTable />
      <div className="d-flex gap-2 align-items-end fw-bold justify-content-end">
        <p className="font-size-12 pb-1">Łącznie</p>
        <p className="text-warning fw-bold font-size-22"> {formatPrice(basketDetails?.total_price_brutto || 0)}</p>
      </div>
      <div className="row justify-content-between mt-3 gx-3">
        {/*<div className="d-flex position-relative">*/}
        {/*  <Input placeholder="kupon-rabatowy-2022" className="cart__coupon__input" name="coupon" />*/}
        {/*  <Button variant="secondary" className="border-medium font-size-12 fw-semi-bold cart__coupon__confirm-button">*/}
        {/*    Zastosuj kupon*/}
        {/*  </Button>*/}
        {/*</div>*/}

        <ReturnButton />
        <Button
          disabled={isNextDisabled}
          className="border-medium px-4 py-3 py-lg-2 d-block ms-auto font-size-12 fw-bold col-md-auto col-6"
          onClick={nextStep}
        >
          <IconWithText
            text="Przejdź dalej"
            icon="uil-angle-right font-size-20 text-white"
            textClassName="text-white"
            className="flex-row-reverse"
          />
        </Button>
      </div>
    </div>
  )
}
