import * as React from 'react'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { InputErrorMessage } from '@components/controls/input-error-message'
import { useInputNumberValue } from '@components/controls/useInputNumberValue'

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
}

export const Input = ({ name, ...props }: Props): JSX.Element => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const errorMessage = errors[name]
  const { getNumberValue } = useInputNumberValue(props)

  const handleChange = event => {
    if (props.type === 'number') {
      setValue(name, getNumberValue(event.target.value))
      return
    }

    setValue(name, event.target.value)
  }

  const value = watch(name)

  return (
    <>
      <input
        {...props}
        {...register(name)}
        onChange={handleChange}
        value={value}
        className={classNames('border-medium py-2 px-4 border-green-light custom-input ', props.className, {
          'is-invalid': !!errorMessage,
        })}
      />
      <InputErrorMessage name={name} />
    </>
  )
}
