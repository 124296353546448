import * as React from 'react'
import { SidebarCategories } from '@components/sidebar/sidebar-categories'
import { CategorySidebarFilters } from '@modules/shop/category-details/filters/category-sidebar-filters'
import { SidebarContact } from '@components/sidebar/sidebar-contact'
import { useProductFilters } from '@modules/shop/category-details/use-product-filters'

export const CategorySidebar = (): JSX.Element => {
  const filters = useProductFilters()

  return (
    <div>
      <SidebarCategories />
      <CategorySidebarFilters filters={filters} />
      <div className="sidebar__contact-card mt-5">
        <SidebarContact />
      </div>
      <img className="my-4" src={require('@assets/images/logo-isf.svg')} alt="" />
    </div>
  )
}
