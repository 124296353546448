import * as React from 'react'
import { ProductCard } from '@components/product-card'

interface Props {
  products: any
}

export const ProductDetailsRelatedProducts = ({ products }: Props): JSX.Element | null => {
  if (!products.length) {
    return null
  }

  return (
    <div>
      <p className="text-success font-size-20 fw-semi-bold">Powiązane produkty</p>
      <div className="d-flex justify-content-between">
        {products.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  )
}
