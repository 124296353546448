import * as React from 'react'

interface Props {
  title: string
  image: string
}

export const SeedCollectionItem = ({ title, image }: Props): JSX.Element => (
  <div>
    <img src={require(`@assets/images/${image}`)} width={100} />
    <p>{title}</p>
  </div>
)
