import * as React from 'react'
import { PageLayout } from '@components/page-layout'
import { ProductCard } from '@components/product-card'
import { ItemLayout } from '@components/item-layout'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CategorySidebar } from '@modules/shop/category-details/category-sidebar'
import { ContentLoader } from '@components/content-loader'
import { CategoryFilters } from '@modules/shop/category-details/filters/category-filters'
import { IsfSidebar } from '@modules/shop/common/isf-sidebar'
import { CategoryFiltersFormInputs, pageOptions, productSortOptions } from '@modules/shop/category-details'
import { useSearchParams } from 'react-router-dom'
import { useGetProductQuery } from '@api/products'
import { TypedQueryResult } from '@api/base'
import { Product } from '@models/products'

const emptyList = []

export const SearchView = (): JSX.Element => {
  const [page, setPage] = React.useState(1)

  const [searchParams] = useSearchParams()

  const search = searchParams.get('q') || ''

  const methods = useForm<CategoryFiltersFormInputs>({
    defaultValues: {
      ordering: productSortOptions[0],
      perPage: pageOptions[0],
      price_brutto: undefined,
      producers: [],
      destinations: [],
    },
  })

  const watchedFilter = useWatch({
    control: methods.control,
  })

  const onPageChange = (item: { selected: number }) => setPage(item.selected + 1)

  const { data: products = emptyList, isLoading } = useGetProductQuery({
    search,
    page,
    page_size: watchedFilter.perPage?.value || '16',
    ordering: watchedFilter.ordering?.value || 'price_brutto',
    price_brutto: watchedFilter.price_brutto,
    destinations: watchedFilter.destinations,
    producers: watchedFilter.producers,
  }) as TypedQueryResult<Product[]>

  return (
    <PageLayout
      sidebar={
        <FormProvider {...methods}>
          <CategorySidebar />
        </FormProvider>
      }
    >
      <IsfSidebar />

      <div>
        <ContentLoader isLoading={isLoading}>
          {!products.length ? (
            <p className="text-center">Nie znaleziono szukanego produktu ;-(</p>
          ) : (
            <FormProvider {...methods}>
              <ItemLayout
                perPage={watchedFilter.perPage?.value || '10'}
                filters={<CategoryFilters productSortOptions={productSortOptions} pageOptions={pageOptions} />}
                onPageChange={onPageChange}
              >
                {layoutView => (
                  <div className="row my-4 gy-4">
                    {products.map(product => (
                      <ProductCard layoutView={layoutView} key={product.id} product={product} />
                    ))}
                  </div>
                )}
              </ItemLayout>
            </FormProvider>
          )}
        </ContentLoader>
      </div>
    </PageLayout>
  )
}
