import * as React from 'react'
import { HomeWelcomeSection } from '@modules/home/home-welcome-section'
import { HomeProductsList } from '@modules/home/home-products-list'
import { HomeHintSection } from '@modules/home/home-hint-section'
import { HomeBlogSection } from '@modules/home/home-blog-section'
import { HomeSeedsGallerySection } from '@modules/home/home-seeds-gallery-section'
import { PageLayout } from '@components/page-layout'
import { fakeProducts, SidebarRecommendedList } from '@components/sidebar/sidebar-recommended-list'
import { SidebarContact } from '@components/sidebar/sidebar-contact'

export const HomeView: React.FC = () => (
  <PageLayout>
    <div className="d-flex flex-column gap-lg-5 gap-3">
      <HomeWelcomeSection />
      <HomeProductsList />
      <HomeHintSection
        name="Krzysztof Baran"
        avatar="recommended-item.png"
        description="Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi. Oferujemy nasiona traw, kwiatów, drzew ozdobnych i owocowych, kłącza, bulwy oraz cebule. Prowadzimy sprzedaż materiałów szkółkarskich oraz artykułów pokrewnych..."
        text="Jakie rośliny są najlepsze do sadzenia w domu?"
      />
      <div className="d-block d-lg-none">
        <SidebarRecommendedList />
        <div className="sidebar__contact-card my-5">
          <SidebarContact />
        </div>
        <img className="mb-4" src={require('@assets/images/logo-isf.svg')} alt="" />
      </div>

      <HomeBlogSection articles={blogArticles} />
      <HomeSeedsGallerySection images={images} />
      <hr />
      <p className="pb-5">
        Witamy serdecznie w naszym sklepie Internetowym z nasionami ! <br />
        Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które
        wyrasta z ziemi. Oferujemy nasiona traw, kwiatów, drzew ozdobnych i owocowych, kłącza, bulwy oraz cebule.
        Prowadzimy sprzedaż materiałów szkółkarskich oraz artykułów pokrewnych i uzupełniających. Tworzymy kompleksowy
        asortyment pozwalający na stworzenie wyjątkowego ogrodu na prywatnej posesji bądź efektownego terenu zielonego
        przy instytucjach użytku publicznego lub firmach. Aktualnie w naszej ofercie znajduje się ponad 300 produktów.
        Dostarczmy wysokiej jakości produkty powstałe w wyniku starannej uprawy, selekcjonowane i pieczołowicie
        przygotowane do wysyłki. Posiadamy szeroki wybór produktów w wielu różnych, ciekawych odmianach. Doradzamy także
        w zakresie doboru roślin oraz przygotowania odpowiednich warunków pod przyszłe mateczniki, rabaty i trawniki.
      </p>
    </div>
  </PageLayout>
)

export const recommendedItems = {
  news: fakeProducts,
  bestsellers: fakeProducts,
  most_popular: fakeProducts,
}

export const blogArticles = [
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
]

const images = [
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
]
